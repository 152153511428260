.our-team {
  /* animation */
  .sec2 {
    .txt-box {
      h2 > span {
        opacity: 0;
        transform: translateY(110%);
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &.ani.active {
        &.not {
          h2 span {
            transition: none;
          }
        }

        h2 > span {
          opacity: 1;
          transform: translateY(0);
        }

        h2.step2 > span {
          transition-delay: 0.1s;
        }
        h2.step3 > span {
          transition-delay: 0.2s;
        }
        h2.step4 > span {
          transition-delay: 0.3s;
        }
      }
    }

    .video-box {
      video,
      .video {
        opacity: 0;
        transform: scale(1.11);
        transition: 1.05s ease;
      }

      &.ani.active {
        video,
        .video {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  .sec3 {
    .p-box {
      p {
        span {
          opacity: 0;
          transform: translateY(110%);
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      &.ani.active {
        &.not {
          p span {
            transition: none;
          }
        }

        p {
          span {
            opacity: 1;
            transform: translateY(0);
          }
          &:nth-child(1) span {
            transition-delay: 0.1s;
          }
          &:nth-child(2) span {
            transition-delay: 0.2s;
          }
          &:nth-child(3) span {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }

  .sec1 {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;

    h1 {
      ::selection {
        background: #fff;
        color: #000;
      }
      ::-moz-selection {
        background: #fff;
        color: #000;
      }

      > span {
        font-size: 8.267195767195767vw;
        color: #fff;
        letter-spacing: -0.24vw;

        .pd-font {
          color: #fff;
        }
      }
    }
  }
  .sec2 {
    position: relative;
    background: #000;

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }

    .txt-box {
      margin-top: 1.05vw;
    }

    h2 {
      margin-bottom: -2vw;
      padding-bottom: 2vw;
      font-size: 0;
      overflow: hidden;
      display: block;

      ::selection {
        background: #fff;
        color: #000;
      }
      ::-moz-selection {
        background: #fff;
        color: #000;
      }

      .pc,
      .we-are {
        display: inline !important;
      }

      > span {
        opacity: 0;
        display: block;
        font-size: 6.613756613756614vw;
        color: #fff;
        letter-spacing: -0.2vw;
        line-height: 1.229;

        span {
          color: #fff;
        }

        .underline {
          position: relative;
          display: inline-block;
          color: #fff;

          &:before {
            content: "";
            position: absolute;
            top: 8.44vw;
            left: 0;
            width: 100%;
            height: 0.55vw;
            background: #fff;
          }
        }

        sup {
          margin: -0.8vw 0 0 0.11vw;
          display: inline-block;

          span {
            font-size: 3.8vw;
            letter-spacing: -0.02vw;
            color: #fff;
          }

          .pd-font {
            font-weight: 600;
          }

          .total-employee {
            font-weight: bold;
          }
        }

        i {
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .video-box {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 52.71164021164021%;

      video,
      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .sec3 {
    padding-bottom: 9.4vw;

    .part {
      padding: 9.15vw 0 3vw;

      h2 {
        > span {
          width: 100%;
          font-size: 6.613756613756614vw;
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: -0.2vw;

          sup {
            display: inline-block;
            margin: 0.8vw 0 0 0.25vw;
            font-size: 1.984126984126984vw;
            letter-spacing: -0.08vw;

            .num {
              margin-left: 0.3vw;
            }
          }
        }
      }

      .p-box {
        margin-top: 0.65vw;
        padding: 1.5vw 0.1vw 1.3vw;

        p {
          font-size: 0;
          overflow: hidden;
          display: block;

          span {
            opacity: 0;
            display: inline-block;
            font-size: 1.322751322751323vw;
            text-transform: capitalize;
            line-height: 1.56;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .our-team {
    .sec1 {
      h1 {
        > span {
          font-size: 13.33333333333333vw;
          letter-spacing: -0.38vw;
        }
      }
    }
    .sec2 {
      h2 {
        padding: 2vw 0 2vw;
        margin: -2vw 0 -3.8vw;

        &.step4 {
          margin-bottom: 0;
        }

        > span {
          font-size: 10.66666666666667vw;
          line-height: 1.223;
          letter-spacing: -0.32vw;

          span {
            &.underline {
              margin-top: 0;

              &:before {
                top: 13.5vw;
                height: 1vw;
              }
            }
          }

          sup {
            margin: -1.2vw 0 0 1vw;
            font-size: 4.533333333333333vw;
          }
        }
      }

      .video-box {
        padding-top: 121.8666666666667%;

        .video > div {
          box-sizing: border-box;
          width: calc(1vh * 189.7173211914248);
          width: calc(var(--vh, 1vh) * 189.7173211914248);
          height: 52.71vw;
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .sec3 {
      padding-bottom: 20vw;

      .part {
        padding: 13vw 0 4vw;

        .inner {
          position: relative;
        }

        h2 {
          > span {
            padding-top: 6.4vw;
            font-size: 16vw;
            letter-spacing: -0.5vw;

            sup {
              position: absolute;
              top: -5%;
              left: 0;
              width: 100%;
              display: block;
              margin: 0;
              font-size: 8vw;
              letter-spacing: -0.3vw;
              text-align: center;

              .num {
                display: inline-block;
                vertical-align: middle;
                font-size: 7.5vw;
                margin: -1.8vw 0 0 1vw;
              }
            }
          }
        }

        .p-box {
          margin-top: 1.7vw;
          padding: 3.5vw 0.1vw 3vw;

          p {
            span {
              font-size: 6.666666666666667vw;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
