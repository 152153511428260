.next-project {
  position: relative;
  padding-bottom: 8.7vw;
  z-index: 1;

  .flex-box {
    display: flex;
    justify-content: space-between;

    > div {
      opacity: 1;
      transition: none;
      transform: none;
    }
  }

  .txt-box {
    h2 {
      > span {
        padding-top: 0.5vw;
        font-size: 5.6vw;
        line-height: 7vw;
        letter-spacing: -0.18vw;

        i {
          font-family: "Playfair Display";
          letter-spacing: -0.2vw;
        }
      }
    }

    .arrow-txt {
      .txt-wrap {
        transition-delay: 0.1s;
        margin-top: -0.6vw;
        font-size: 4.533333333333333vw;
        line-height: 1.3;
        letter-spacing: -0.1vw;
      }
    }
  }

  .project-lists {
    padding-bottom: 0;

    > ul {
      display: inline-block;

      > li {
        display: inline-block;
        vertical-align: top;
        padding-top: 2.7vw;

        &.type1 {
          width: 24.6031746031746vw;

          a {
            width: 100%;
          }
        }

        &.type2 {
          width: 24.6031746031746vw;

          a {
            width: 100%;
          }
        }

        &.type3 {
          width: 32.8042328042328vw;

          a {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .work-view {
    .next-project .project-lists > ul:first-child > li {
      &:first-child {
        padding-top: 16.5vw;
      }

      padding-top: 16.5vw;
    }
  }

  .next-project {
    padding-bottom: 21.7vw;

    .flex-box {
      display: block;
    }

    .txt-box {
      h2 {
        span {
          padding-top: 0.2vw;
          font-size: 8vw;
          line-height: 11vw;
        }
      }

      .arrow-txt {
        .txt-wrap {
          margin-top: -1vw;
        }
        > span {
          font-size: 4.533333333333333vw;
        }
      }
    }

    .project-lists > ul {
      display: block;

      > li {
        padding-top: 16.5vw;
        width: 100% !important;

        &.type1 {
          text-align: right;

          a {
            width: 60.53333333333333vw;
          }
        }

        &.type2 {
          a {
            width: 60.53333333333333vw;
          }
        }

        &.type3 {
          a {
            width: 100%;
          }
        }
      }
    }
  }
}
