.main footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  .work-with-us {
    display: none;
  }
}

.contact footer {
  .work-with-us {
    display: none;
  }
}

footer {
  position: relative;
  z-index: 1;

  .work-with-us {
    padding: 2.1vw 0 1.4vw;
    margin-bottom: 1.6vw;
    transition: all 0.2s;

    &:hover {
      padding-left: 2vw;
      background: #000;

      span {
        color: #fff;
      }

      i {
        color: #fff;
      }

      .arrow {
        span {
          background: url(../../../../public/images/wrok_with_us_arrow_w.png) center no-repeat;

          &.step1 {
            transform: translate3d(60%, -60%, 0);
            opacity: 0;
            transition-delay: 0s;
          }

          &.step2 {
            transform: translate3d(0, 0, 0) rotate(0deg);
            opacity: 1;
            transition-delay: 0.1s;
          }
        }
      }
    }

    a {
      transition: none !important;

      > span {
        padding-right: 3vw;
        font-size: 6.613756613756614vw;
        line-height: 1.1;
        letter-spacing: -0.185vw;
      }

      i {
        font-family: "Playfair Display";
        font-weight: 500;
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }

      .arrow {
        margin: -0.9vw 0 0 1.27vw;
        width: 3.306878306878307vw;
        height: 3.306878306878307vw;

        span {
          background: url(../../../../public/images/wrok_with_us_arrow.png) center no-repeat;
        }
      }
    }
  }

  .info-box {
    width: 100%;
    padding-bottom: 21px;
    display: flex;
    justify-content: space-between;
  }

  .left-box {
    ul {
      display: flex;
      flex-direction: row;
      align-items: end;

      li {
        padding-right: 53px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.45px;
      }
    }
  }

  .right-box {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: -0.1px;

    img {
      display: block;
      width: 161px;
    }

    .f-logo-w {
      display: none;
    }

    p {
      margin-top: 5px;
      letter-spacing: -0.35px;
    }
  }

  .admin-link {
    margin-bottom: 2px;

    i {
      font-family: "Playfair Display";
      font-weight: 500;
    }

    &.arrow-txt {
      .arrow {
        margin: -5px 3px 0 3px;
        width: 9px;
        height: 9px;

        span {
          background: url(../../../../public/images/footer_arrow.png) center no-repeat;
        }
      }
    }
  }
}

.black {
  footer {
    .work-with-us {
      a {
        > span {
          color: #fff;
        }

        i {
          color: #fff;
        }

        .arrow {
          span {
            background: url(../../../../public/images/wrok_with_us_arrow_w.png) center no-repeat;
          }
        }
      }

      &:hover {
        background: #fff;

        span {
          color: #000;
        }

        i {
          color: #000;
        }

        .arrow {
          span {
            background: url(../../../../public/images/wrok_with_us_arrow.png) center no-repeat;
          }
        }
      }
    }

    .left-box {
      ul {
        li {
          color: #fff;
        }
      }
    }

    .right-box {
      .f-logo-b {
        display: none;
      }

      .f-logo-w {
        display: block;
      }

      p {
        color: #fff;
      }
    }

    .admin-link {
      color: #fff;

      i {
        color: #fff;
      }

      &.arrow-txt {
        .arrow {
          span {
            background: url(../../../../public/images/footer_arrow_w.png) center no-repeat;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  footer {
    .left-box {
      ul {
        li {
          padding-right: 25px;
          font-size: 12px;
          letter-spacing: -0.2px;
        }
      }
    }
    .right-box {
      font-size: 12px;
      letter-spacing: -0.2px;

      img {
        width: 115px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  footer {
    .inner {
      padding: 0;
    }

    .work-with-us {
      padding: 3.4vw 3.733333333333333vw 3vw;
      margin-bottom: 0.1vw;

      &:hover {
        padding-left: 6vw;
      }

      a {
        > span {
          font-size: 8vw;
          letter-spacing: -0.25vw;
        }

        .arrow {
          margin: 0 0 0 2.8vw;
          width: 4.109333333333333vw;
          height: 4.221333333333333vw;
        }
      }
    }

    .info-box {
      padding: 0 3.733333333333333vw 11px;
      align-items: end;
    }

    .left-box {
      ul {
        flex-direction: column;
        align-items: unset;

        li {
          padding: 14px 0 0 0;
          font-size: 10px;
          line-height: 1.36;
        }
      }
    }
    .right-box {
      width: 60%;
      font-size: 10px;
      line-height: 1.8;

      .admin-link {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 1;
      }

      p {
        margin-top: 6px;
        line-height: 1.35;
      }
    }
  }
}
