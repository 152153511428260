.cover {
  display: none;
}

.index .cover {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: #000;
  z-index: 6;
  overflow: hidden;
  animation: cover-up 1s cubic-bezier(0.5, 0.8, 0.2, 1) both 4.3s;

  .cover-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cont-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;

    .circle {
      opacity: 0;
      margin: 0 13px;
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background: #fff;
      animation: fadeIn 1s ease-in both;

      &:nth-child(1) {
        animation-delay: 1s;
      }
      &:nth-child(2) {
        animation-delay: 1.2s;
      }
      &:nth-child(3) {
        animation-delay: 1.4s;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: -5%;
      left: 0;
      width: 100%;
      height: 110%;
      background: #000;
      animation: posY-100per-up 0.5s ease both 2.8s;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      h1 {
        font-size: 0;
        line-height: 0;
        overflow: hidden;

        span {
          display: inline-block;
          font-size: 24px;
          color: #fff;
          line-height: 1;
          font-weight: 500;
          animation: posY-100per-up 0.5s ease both 3.3s;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cover {
    .cover-wrap {
      .cont-wrap {
        padding: 0;
        width: 100%;

        .circle {
          margin: 0 1.333333333333333vw;
          width: 8vw;
          height: 8vw;
        }

        .text h1 span {
          font-size: 4vw;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
