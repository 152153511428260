.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;

  .bg {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video {
      position: relative;
      width: 100%;
      height: 100%;

      iframe {
        box-sizing: border-box;
        width: calc(var(--vh, 1vh) * 177.7777777777778);
        height: 56.25vw;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.on {
      opacity: 1;
    }
  }

  .content {
    width: 100%;
    height: 100%;

    .react-swiper {
      position: absolute;
      top: 5.4vh;
      left: 0;
      width: 100%;
      display: block;
      font-size: 0;

      &.pc {
        .swiper-wrapper {
          width: calc(var(--vh, 1vh) * 220);
        }

        .marquee {
          font-size: calc(var(--vh, 1vh) * 13.63);
        }
        display: block !important;
      }

      &.tablet {
        top: -100vw;
        opacity: 0;
        display: block !important;

        .swiper-wrapper {
          width: calc(var(--vh, 1vh) * 177);
        }

        .marquee {
          font-size: calc(var(--vh, 1vh) * 11);
        }
      }

      &.mo {
        top: -100vw;
        opacity: 0;
        display: block !important;

        .swiper-wrapper {
          width: calc(var(--vh, 1vh) * 110);
        }

        .marquee {
          font-size: calc(var(--vh, 1vh) * 6.9);
        }
      }
    }
    .marquee {
      display: inline-block;
      font-weight: 600;
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      z-index: 1;

      span {
        font-family: "Playfair Display";
        font-style: italic;
        font-weight: 500;
      }
    }
  }

  .circle-box {
    padding-top: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .circle-box-wrap {
      position: relative;
      display: flex;

      .circle {
        position: relative;
        margin: 0 4.8vh;
        width: 28.5vh;
        border-radius: 100%;
        background: #000;
        overflow: hidden;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          background: transparent;
        }

        .circle-wrap {
          position: relative;
          width: 100%;
          padding-top: 100%;
          border-radius: 100%;
          overflow: hidden;

          .text-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            overflow: hidden;

            .top-txt {
              margin-left: 10.4vh;
              font-size: 2.2vh;
              line-height: 1.5;
              letter-spacing: -0.11vh;
              transition: all 0.2s;
              i {
                font-family: "Playfair Display";
                transition: all 0.2s;
              }
            }
            .bottom-txt {
              margin-right: 5.1vh;
              font-size: 6.3vh;
              font-weight: 600;
              line-height: 1;
              letter-spacing: -0.3vh;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .main .content {
    .react-swiper {
      &.pc {
        top: -100vw;
        opacity: 0;
      }

      &.tablet {
        top: 6vh;
        opacity: 1;
      }
    }
  }
  .main .circle-box {
    padding-top: calc(var(--vh, 1vh) * 42.6);

    .circle-box-wrap .circle {
      margin: 0 calc(var(--vh, 1vh) * 3);
      width: calc(var(--vh, 1vh) * 19);
    }
  }
}

@media screen and (max-width: 767px) {
  .main {
    .content {
      .react-swiper {
        &.tablet {
          top: -100vw;
          opacity: 0;
        }

        &.mo {
          top: calc(var(--vh, 1vh) * 7.3);
          opacity: 1;
          display: block !important;
        }
      }
    }

    .circle-box {
      padding-top: calc(var(--vh, 1vh) * 39.9);

      .circle-box-wrap {
        .circle {
          margin: 3.04vw;
          width: 18.66666666666667vw;

          .circle-wrap {
            .text-box {
              .top-txt {
                margin-left: calc(var(--vh, 1vh) * 2.5);
                font-size: calc(var(--vh, 1vh) * 1.8);
                letter-spacing: calc(var(--vh, 1vh) * -0.05);
              }

              .bottom-txt {
                margin-right: calc(var(--vh, 1vh) * 1.7);
                font-size: calc(var(--vh, 1vh) * 3.8);
                letter-spacing: calc(var(--vh, 1vh) * -0.2);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes posY-100per-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes cover-up {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
