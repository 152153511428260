header {
  position: relative;
  overflow: hidden;

  .hide {
    position: absolute;
    top: 0;
    left: 200vw;
    width: 100%;
    z-index: -1;
  }

  .header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 25px 0;
    mix-blend-mode: difference;
    z-index: 5;
  }

  .logo {
    position: relative;
    width: 155px;
    line-height: 0;

    a {
      width: 100%;
    }

    .black-logo {
      display: none;
    }

    img {
      transition: opacity 0.3s;
    }
  }

  &.white-logo {
    .black-logo {
      opacity: 0;
    }

    .white-logo {
      opacity: 1;
    }
  }

  nav {
    ul li {
      padding: 0 8.4px;
      display: inline-block;

      &:last-child {
        padding-right: 0;
      }
      a {
        position: relative;
        padding: 2px 7px 1px;
        font-size: 18px;
        line-height: 17px;
        border-radius: 9.5px;
        color: #fff;
        overflow: hidden;

        &:hover {
          background: #fff;
          color: #000;
        }

        &.active {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .menu-btn {
    display: none;
    position: relative;
    width: 21px;
    height: 21px;
    font-size: 0;
    line-height: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  header {
    .header-wrap {
      padding: 0 0 0 14px;

      .logo {
        width: 104px;
      }

      .menu-btn {
        display: flex;
        width: 40px;
        height: 44px;

        span {
          display: inline-block;
          margin-bottom: 2px;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #fff;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    nav {
      opacity: 0;
      position: fixed;
      top: 44px;
      left: 0;
      width: 100%;
      border-top: 1px solid #000;
      background: #fff;
      transition: opacity 0.3s;
      z-index: -1;

      ul li {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #000;

        a {
          display: block;
          padding: 7.34vw 14px 7.44vw;
          font-size: 6.666666666666667vw;
          border-radius: 0;

          &.active {
            border-radius: 0;
          }
        }
      }
    }

    &.active {
      .header-wrap {
        mix-blend-mode: unset;
        background: #fff;
        transition: background-color 0.3s;

        .white-logo {
          display: none;
        }

        .black-logo {
          display: block;
        }
      }

      nav {
        opacity: 1;
        z-index: 2;

        ul li a {
          color: #000;
        }
      }
      .menu-btn {
        span {
          display: none;
          margin-bottom: 0;
          background: #000;
          &:nth-child(2) {
            display: block;
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }
}
