.employeeLists {
  margin: 4.28vw 0 6.75vw;
  font-size: 0;

  &.ani2.active {
    .react-swiper {
      transition-delay: 0.2s;

      @media screen and (max-width: 767px) {
        transition-delay: 0.1s;
      }
    }
  }

  .react-swiper {
    display: block;
  }

  .swiper-container {
    .swiper-wrapper {
      display: flex;

      .swiper-slide {
        width: auto;
        padding: 0 1.653439153439153vw;

        a {
          display: flex;
          flex-direction: column;
          width: 17.52645502645503vw;

          .img-box {
            position: relative;
            display: inline-block;
            width: 100%;
            padding-top: 17.52645502645503vw;
            border-radius: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }
          }

          .text-box {
            margin-top: 2.2vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            .name {
              font-size: 1.984126984126984vw;
              line-height: 1.3;
              letter-spacing: -0.05vw;
              text-align: center;
            }

            .job {
              font-size: 1.322751322751323vw;
              line-height: 1.6;
              letter-spacing: -0.04vw;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .employeeLists {
    margin: 10vw 0 28.5vw;

    .swiper-container {
      .swiper-wrapper {
        margin-left: -3.266666666666667vw;

        .swiper-slide {
          padding: 0 3.266666666666667vw;

          a {
            width: 34.28533333333333vw;

            .img-box {
              padding-top: 34.28533333333333vw;
            }

            .text-box {
              margin-top: 2.8vw;

              .name {
                font-size: 4.533333333333333vw;
                line-height: 1.3;
                letter-spacing: -0.05vw;
              }

              .job {
                font-size: 4vw;
                line-height: 1.3;
                letter-spacing: -0.13vw;
              }
            }
          }
        }
      }
    }
  }
}
