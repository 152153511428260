.passing-page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: -1;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    background: #ccc;
  }

  &.active {
    z-index: 6;
    animation: cover-up 0.5s cubic-bezier(0.5, 0.8, 0.2, 1) both 0.8s;

    &:before {
      animation: come-up 0.5s cubic-bezier(0.5, 0.8, 0.2, 1) both;
    }
  }
}
@keyframes come-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
