/* animation */
.work-view {
  .sec1 {
    .video-box {
      video,
      .video {
        opacity: 0;
        transform: scale(1.11);
        transition: 1.05s ease;
      }
      &.ani.active {
        &.not {
          video,
          .video {
            transition: none;
          }
        }
        video,
        .video {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    .img-box {
      img {
        opacity: 0;
        transform: scale(1.11);
        transition: 1.05s ease;
      }
      &.ani.active {
        &.not {
          img {
            transition: none;
          }
        }
        img {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    .inner {
      h1 span {
        opacity: 0;
        transform: translateY(110%);
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &.ani.active {
        &.not {
          h1 span {
            transition: none;
          }
        }

        h1 {
          &:nth-child(2) span {
            transition-delay: 0.1s;
          }
          &:nth-child(3) span {
            transition-delay: 0.2s;
          }
          span {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }
  .sec2 {
    .detail-lists {
      ul li > span {
        opacity: 0;
        transform: translateY(110%);
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &.ani.active {
        &.not {
          ul li span {
            transition: none;
          }
        }

        ul {
          li {
            > span {
              opacity: 1;
              transform: translateY(0);
            }

            &:nth-child(1) span {
              transition-delay: 0.3s;
            }
            &:nth-child(2) span {
              transition-delay: 0.4s;
            }
            &:nth-child(3) span {
              transition-delay: 0.5s;
            }
            &:nth-child(4) span {
              transition-delay: 0.6s;
            }

            @media screen and (max-width: 767px) {
              &:nth-child(1) span {
                transition-delay: 0.1s;
              }
              &:nth-child(2) span {
                transition-delay: 0.2s;
              }
              &:nth-child(3) span {
                transition-delay: 0.3s;
              }
              &:nth-child(4) span {
                transition-delay: 0.4s;
              }
            }
          }
        }
      }
    }

    .description p span {
      transition-delay: 0.3s;

      @media screen and (max-width: 767px) {
        transition-delay: 0.4s;
      }
    }

    .detail {
      &.line-top {
        &:before {
          transition-delay: 0.3s;

          @media screen and (max-width: 767px) {
            transition-delay: 0.1s;
          }
        }
      }
    }

    .ttt {
      @media screen and (max-width: 767px) {
        .img-box {
          img,
          span {
            transition-delay: 0.2s;
          }
        }

        .txt span {
          transition-delay: 0.2s;
        }
      }
    }

    .member-lists {
      transition: none;
    }
  }
  .sec3 {
    .img-box {
      img {
        opacity: 0;
        width: 100%;
        transform: scale(1.11);
        transition: 1.05s ease;
      }
      &.ani.active {
        &.not {
          img {
            transition: none;
          }
        }

        img {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.work-view {
  .sec1 {
    display: flex;
    flex-direction: column-reverse;

    .title {
      padding: 0.4vw 0 5.5vw;

      h1 {
        display: block;
        font-size: 0;
        overflow: hidden;

        span {
          opacity: 0;
          display: block;

          &.ko {
            font-family: "Pretendard";
            font-size: 8.928571428571429vw;
            line-height: 1.13;
            font-weight: 500;
            letter-spacing: -0.27vw;
          }

          &.en {
            font-size: 8.928571428571429vw;
            line-height: 1;
            letter-spacing: -0.267vw;
          }
        }
      }
    }

    .video-box {
      background: #000;
    }
    .video-box,
    .img-box {
      position: relative;
      height: calc(var(--vh, 1vh) * 100);

      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video {
        position: relative;
        width: 100%;
        height: 100%;

        iframe {
          box-sizing: border-box;
          width: calc(var(--vh, 1vh) * 177.7777777777778);
          height: 56.25vw;
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .sec2 {
    .detail {
      display: flex;

      &.step1 {
        padding: 1vw 0 5vw;
      }

      &.step2 {
        padding: 0 0 9.4vw;
      }

      > div:nth-child(1) {
        padding: 0.95vw 2vw 0 0;
        width: 17%;
      }

      > div:nth-child(2) {
        width: 83%;
      }
    }

    .detail-lists {
      padding-top: 0 !important;

      ul {
        li {
          font-size: 0;
          overflow: hidden;

          > span {
            opacity: 0;
            display: inline-block;
            font-size: 1.124338624338624vw;
            line-height: 1.45;
            letter-spacing: -0.03vw;

            span {
              font-family: "Pretendard";
              font-size: 1.058201058201058vw;
              font-weight: 500;
              letter-spacing: -0.03vw;
            }
          }
        }
      }
    }

    .description {
      p {
        margin-top: -0.2vw;
        width: 58%;

        > span {
          font-family: "Pretendard";
          font-size: 1.124338624338624vw;
          font-weight: 500;
          line-height: 1.47;
          letter-spacing: -0.035vw;
        }
      }
    }

    .ttt {
      &:after {
        display: none;
      }

      .img-box {
        img {
          padding-bottom: 0.4vw;
          width: 8.8vw;
        }
        span {
          padding-bottom: 0.1vw;
          font-size: 1.124338624338624vw;
          line-height: 1.5;
          font-weight: bold;
        }
      }

      .txt {
        position: relative;
        display: block;

        > span {
          display: block;
          font-size: 1.124338624338624vw;
          line-height: 1.5;

          .with {
            position: absolute;
            top: 0;
            left: 17%;
            font-family: "Pretendard";
            font-size: 0.9920634920634921vw;
            font-weight: 600;
            letter-spacing: -0.05vw;
          }
        }
      }
    }

    .member-lists {
      .member-lists-ul {
        > li {
          padding: 0.5vw 0 0.25vw;
        }
        .wrap {
          position: relative;
          width: 100%;
          display: flex;

          .field {
            margin-top: 0.38vw;
            display: inline-block;
            width: 16vw;
            font-size: 1.124338624338624vw;
            font-weight: 500;
            line-height: 1.47vw;
            letter-spacing: -0.035vw;
          }

          .name-list-ul {
            flex: 1;
          }
        }
      }
      ul.name-list-ul {
        overflow: hidden;

        li {
          float: left;
          display: inline-block;
          margin: 0.34vw 0.3306878306878307vw;

          .invert-btn {
            border-radius: 1.5vw;

            .btn-wrap {
              border-radius: 1.5vw;

              a {
                padding: 0.12vw 0.486vw 0.137vw;
                font-size: 0.9259259259259259vw;
                letter-spacing: -0.03vw;
                line-height: 1.1vw;
              }
            }
          }
        }
      }
    }
  }

  .sec3 {
    padding-bottom: 4.7vw;

    .content {
      &.type1 {
        padding-bottom: 23.1vw;
      }

      &.type2 {
        padding-bottom: 15.15vw;
      }

      .txt-box {
        padding-bottom: 5.35vw;
        display: flex;

        > div {
          transition: none;
        }

        .content-title {
          width: 58%;

          h3 {
            padding-top: 0.9vw;

            > span {
              font-size: 3.968253968253968vw;
              line-height: 1.3;
              letter-spacing: -0.12vw;
            }
          }
        }
        .content-p {
          width: 42%;

          p {
            font-family: "Pretendard";
            padding-top: 1.5vw;
            line-height: 1.6;
            letter-spacing: -0.03vw;
            font-size: 1.058201058201058vw;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .work-view {
    .sec1 {
      display: block;

      .title {
        padding: 14.6vw 0 4.34vw;

        h1 {
          span {
            &.ko {
              font-size: 10.66666666666667vw;
            }

            &.en {
              font-size: 10.66666666666667vw;
            }
          }
        }
      }

      .video-box,
      .img-box {
        height: auto;

        video,
        img {
          width: 100%;
          height: auto;
          object-fit: unset;
        }

        .video {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          height: auto;
        }
      }
    }

    .sec2 {
      .detail {
        display: block;

        &.step1 {
          padding: 3.7vw 0 12.6vw;
          border-top: none;
        }

        &.step1.line-top {
          &:before {
            display: none;
          }
        }

        &.step2 {
          padding: 0 0 19.6vw;
        }

        > div:nth-child(1) {
          padding-right: 0;
          width: 100%;
        }

        > div:nth-child(2) {
          width: 100%;
        }

        .detail-lists {
          padding-top: 0.95vw !important;

          ul {
            li {
              font-size: 2.933333333333333vw;
              line-height: 1.545;

              span {
                font-size: 2.933333333333333vw;
                letter-spacing: -0.13vw;
              }
            }
          }
        }

        .description {
          p {
            margin-top: 4.3vw;
            width: 100%;

            > span {
              font-size: 3.466666666666667vw;
              line-height: 1.6;
              letter-spacing: -0.11vw;
            }
          }
        }

        .ttt {
          position: relative;
          padding: 3vw 0 2.3vw !important;

          &:after {
            display: block;
          }

          .img-box {
            display: inline-block;
            vertical-align: middle;

            img {
              padding-bottom: 0;
              width: 23.64533333333333vw;
            }

            span {
              padding-bottom: 0;
              display: inline-block;
              vertical-align: middle;
              font-size: 3.2vw;
              line-height: 1;
            }
          }

          .txt {
            display: inline-block;
            vertical-align: middle;

            > span {
              display: inline-block;
              vertical-align: middle;
              padding-left: 1.3vw;
              font-size: 3.2vw;
              line-height: 1;

              .with {
                position: relative;
                top: auto;
                left: auto;
                display: inline-block;
                vertical-align: middle;
                font-size: 2.933333333333333vw;
              }
            }
          }
        }

        .member-lists {
          .member-lists-ul {
            > li {
              padding: 2.1vw 0 1.2vw;
            }
            .wrap {
              display: block;

              .field {
                margin-top: 0;
                display: inline-block;
                width: 100%;
                font-size: 3.466666666666667vw;
                line-height: 4.5vw;
                letter-spacing: -0.1vw;
              }

              .name-list-ul {
                margin-top: 1.8vw;
              }
            }
          }

          ul.name-list-ul {
            li {
              margin: 0 2.133333333333333vw 2.133333333333333vw 0;

              .invert-btn {
                border-radius: 4vw;

                .btn-wrap {
                  border-radius: 4vw;

                  a {
                    padding: 1vw 1.767vw 0.93vw;
                    min-width: 2.4vw;
                    font-size: 3.733333333333333vw;
                    line-height: 4vw;
                  }
                }
              }
            }
          }
        }
      }
    }

    .sec3 {
      padding-bottom: 10.5vw;

      .content {
        &.type1 {
          padding-bottom: 66.2vw;
        }

        &.type2 {
          padding-bottom: 18.4vw;
        }

        .txt-box {
          padding: 1.2vw 0 7.2vw;
          display: block;

          .content-title {
            width: 100%;

            h3 {
              font-size: 6.666666666666667vw;
              letter-spacing: -0.2vw;
            }
          }
          .content-p {
            width: 100%;

            p {
              padding-top: 3.2vw;
              letter-spacing: -0.105vw;
              font-size: 3.466666666666667vw;
            }
          }
        }
      }
    }
  }
}

/* 221103 추가 start */
.work-view {
  .text-box {
    margin-top: 6.41025641025641vw;
    display: block;
    position: relative;
  }
  .text-box:before {
    background: #000;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .text-box .text-box-title {
    float: left;
    display: inline-block;
    width: 58%;
    padding-top: 0.9vw;
    padding-right: 5.5556vw;
    padding: 0.9vw 5.5556vw 5.35vw 1.587301587301587vw;
    font-weight: 400;
    font-size: 3.968253968253968vw;
    letter-spacing: -0.12vw;
    line-height: 1.3;
    box-sizing: border-box;
  }
  .text-box .text-box-title::after {
    display: block;
    content: "";
    clear: both;
  }
  .text-box img {
    width: 100%;
    display:block;
  }
  .text-box .text-box-p:nth-child(2) {
    display: inline-block;
    width: 42%;
    font-family: Pretendard;
    font-size: 1.058201058201058vw;
    font-weight: 500;
    letter-spacing: -0.03vw;
    line-height: 1.6;
    padding: 1.5vw 1.587301587301587vw 5.35vw 0;
    box-sizing: border-box;
  }
  @media screen and (max-width: 767px) {
    .text-box {
      display: block;
      margin-top: 0;
      padding: 0;
    }
    .text-box .text-box-title {
      width: 100%;
      letter-spacing: -0.2vw;
      padding: 0.9vw 3.733333333333333vw 0;
      font-size: 3.968253968253968vw;
      letter-spacing: -0.12vw;
      line-height: 12.22222vw;
    }
    .text-box .text-box-p:nth-child(2) {
      width: 100%;
      font-size: 3.466666666666667vw;
      letter-spacing: -0.105vw;
      padding: 3.2vw 3.733333333333333vw 7.2vw;
      line-height: 1.6;
      font-weight: 500;
    }
  }
}
/* 221103 추가 end */

/* 221124 iframe 크기 수정 start */
.scroll-container {
  .scroll-content {
    iframe:not(.sec1 iframe.video) {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 56.25vw;
      z-index: -1;
    }
  }
}

/* 221124 iframe 크기 수정 end */

/* 20230102 pc 텍스트박스 상단 마진 */
@media screen and (min-width: 768px){
  .work-view .text-box {
      margin-top: 200px;
  }
}

.work-view .scroll-container div:not(.next-project) p:not(.text-box-p) > img{width: 100%;}