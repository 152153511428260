/* animation */
.project-cont {
  padding-top: 7.6vw;
}

.project-text-box {
  ul li span {
    opacity: 0;
    transform: translateY(110%);
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.ani.active {
    &.not ul li span {
      transition: none;
    }
    ul li {
      &:nth-child(2) span {
        transition-delay: 0.1s;
      }
      &:nth-child(3) span {
        transition-delay: 0.1s;
      }
      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
.project-img-box {
  img,
  > .video {
    opacity: 0;
    transform: scale(1.11);
    transition: 1.05s ease;
  }

  &.ani.active {
    img,
    .video {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.big-thumbnail {
  padding-bottom: 10.15vw;
  text-align: center;
  font-size: 0;

  a {
    width: 64.02116402116402vw;
    text-align: left;

    &:hover .project-img-box {
      img,
      .video {
        transform: scale(1.06);
      }
    }

    .project-img-box {
      padding-top: 56.30165289256198%;
    }
  }
}

.project-text-box {
  margin-bottom: 1.3vw;

  ul li {
    font-size: 0;
    overflow: hidden;
    display: block;

    span {
      opacity: 0;
      display: inline-block;
      font-size: 1.124338624338624vw;
      line-height: 1.28;
      letter-spacing: -0.035vw;
    }

    .ko {
      font-family: "Pretendard";
      font-weight: 600;
    }

    &:last-child {
      span {
        font-family: "Playfair Display";
      }
    }
  }
}

.project-img-box {
  position: relative;

  img,
  .video {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    iframe {
      position: absolute;
      top: -2% !important;
      right: -2% !important;
      bottom: -2% !important;
      left: -2% !important;
      width: 104% !important;
      height: 104% !important;
    }
  }
}

.project-lists {
  padding-bottom: 16.6vw;

  > ul {
    margin: 0 -0.7936507936507937vw -7.08vw;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }

    > li {
      padding: 0 0.7936507936507937vw 7.08vw;
      width: 33.3333333%;
      float: left;

      &:nth-child(3n + 1) {
        clear: both;
      }

      a {
        display: inline-block;
        width: 100%;

        &:hover {
          .project-img-box {
            img,
            .video {
              transform: scale(1.11);
            }
          }
        }
      }

      &.type1 {
        text-align: right;

        a {
          width: 73.72881355932203%;
          text-align: left;

          .project-img-box {
            padding-top: 74.71264367816092%;
          }
        }
      }

      &.type2 {
        a {
          width: 73.72881355932203%;

          .project-img-box {
            padding-top: 129.3103448275862%;
          }
        }
      }

      &.type3 {
        a {
          width: 100%;

          .project-img-box {
            padding-top: 55.08474576271186%;
          }
        }
      }
    }
  }
}

.black {
  .project-text-box {
    margin-bottom: 1.3vw;

    ul li {
      color: #fff;

      span {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .project-cont {
    padding-top: 20vw;
  }

  .big-thumbnail {
    padding-bottom: 16.15vw;

    a {
      width: 100%;

      .project-img-box {
        padding-top: 56.19596541786744%;
      }
    }
  }

  .project-text-box {
    margin-bottom: 5.3vw;

    ul li span {
      font-size: 4vw;
      letter-spacing: -0.12vw;
    }
  }

  .project-lists {
    padding-bottom: 21.3vw;

    > ul {
      margin: 0;
      display: block;

      &:first-child {
        li:first-child {
          padding-top: 0;
        }
      }

      > li {
        width: 100%;
        float: none;
        padding: 16.3vw 0 0;

        .project-text-box {
          margin-bottom: 5.1vw;

          ul li span {
            font-size: 4vw;
            letter-spacing: -0.11vw;
          }
        }

        &.type1 {
          a {
            width: 60.53333333333333vw;

            .project-img-box {
              padding-top: 72.24669603524229%;
            }
          }
        }

        &.type2 {
          a {
            width: 60.53333333333333vw;

            .project-img-box {
              padding-top: 129.5154185022026%;
            }
          }
        }
      }
    }
  }

  .black {
    .project-text-box {
      margin-bottom: 5.3vw;
    }
  }
}
