.work {
  /* animation */
  .sec1 {
    h1 {
      &.step2.ani.active {
        span {
          transition-delay: 0.1s;
        }
      }
    }

    .p-box {
      p {
        span {
          opacity: 0;
          transform: translateY(110%);
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      &.ani.not {
        p {
          span {
            transition: none;
          }
        }
      }

      &.ani.active {
        p {
          span {
            opacity: 1;
            transform: translateY(0);
          }

          &:nth-child(1) span {
            transition-delay: 0.2s;
          }
          &:nth-child(2) span {
            transition-delay: 0.3s;
          }
          &:nth-child(3) span {
            transition-delay: 0.4s;
          }
          &:nth-child(4) span {
            transition-delay: 0.5s;
          }
          &:nth-child(5) span {
            transition-delay: 0.6s;
          }
        }
      }
    }

    .p-box {
      p {
        font-size: 0;
        overflow: hidden;
        display: block;

        span {
          opacity: 0;
          display: inline-block;
        }
      }
    }
  }

  .sec1 {
    padding: 5.35vw 0 9vw;

    h1 {
      span {
        font-size: 9.920634920634921vw;
        line-height: 0.975;
      }

      &.step1 {
        span {
          letter-spacing: -0.3vw;
        }
      }

      &.step2 {
        span {
          font-family: "Playfair Display";
          font-style: italic;
          font-weight: 500;
        }
      }
    }

    .img1 {
      margin: 0 0.8vw 0 1vw;
      width: 11.64021164021164vw;
    }

    .img2 {
      margin: 1.7vw 0.4vw 0 1.1vw;
      width: 8.928571428571429vw;
    }

    .video1 {
      display: inline-block;
      margin: 1.7vw 0 0 1.6vw;
      width: 15.87301587301587vw;
    }

    .p-box {
      margin: 3.8vw 0 0 16.4vw;

      p {
        display: block !important;
        font-size: 0;
        overflow: hidden;

        span {
          font-family: "Pretendard";
          font-size: 1.322751322751323vw;
          font-weight: 600;
          line-height: 1.6;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .work {
    .sec1 {
      padding: 16.2vw 0 27.9vw;

      h1 {
        font-size: 10.66666666666667vw;
        line-height: 0.92;

        &.step1 {
          letter-spacing: -0.3vw;
        }
      }

      .img1 {
        margin: 0 1.3vw -0.3vw 2vw;
        width: 13.06666666666667vw;
      }

      .img2 {
        margin: 1.7vw 0.4vw 0 1vw;
        width: 9.6vw;
      }

      .video1 {
        margin: 1.9vw 0 0 1.4vw;
        width: 17.45333333333333vw;
      }

      .p-box {
        margin: 8vw 0 0 16vw;

        p {
          span {
            font-size: 3.466666666666667vw;
            line-height: 1.678;
          }
        }
      }
    }
  }
}
