/* common */

body.overflow {
  overflow: hidden;
}
#root {
  position: relative;
  // background: url(../../public/images/test/1.jpg) center bottom no-repeat;
}
.pc {
  display: block !important;
}
.mo {
  display: none !important;
}
.inner {
  padding: 0 1.587301587301587vw;
}
.pd-font {
  font-family: "Playfair Display";
}
.noto-font {
  font-family: "Noto Serif KR";
}
.ptd-font {
  font-family: "Pretendard";
}
.black {
  background: #000;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
  .mo {
    display: block !important;
  }
  .inner {
    padding: 0 3.733333333333333vw;
  }
}

/* page transition */
body.overflow .scroll-container {
  height: calc(var(--vh, 1vh) * 100);
}
.routes.black-page {
  background: #000;
}
.routes.fadeIn.active > div {
  opacity: 0;
  animation: fadeIn 0.3s both;
}

.routes.fadeOut > div {
  opacity: 1;
  transition: opacity 1.05s;
}
.routes.fadeOut.active > div {
  opacity: 0;
}

/* arrow animation */
.arrow-txt {
  .arrow {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    margin: -0.2vw 0.7vw 0 1vw;
    width: 2.05026455026455vw;
    height: 2.116402116402116vw;

    span {
      display: block;
      width: 100%;
      height: 100%;
      background: url(../../public/images/next_project_arrow.png) center no-repeat;
      background-size: 100% auto !important;
      transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

      &.step1 {
        transform: translate3d(0, 0, 0);
        transition-delay: 0.1s;
      }

      &.step2 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translate3d(-60%, 60%, 0);
        opacity: 0;
      }
    }
  }

  &:hover {
    .arrow {
      span {
        &.step1 {
          transform: translate3d(60%, -60%, 0);
          opacity: 0;
          transition-delay: 0s;
        }

        &.step2 {
          transform: translate3d(0, 0, 0) rotate(0deg);
          opacity: 1;
          transition-delay: 0.1s;
        }
      }
    }
  }
}

/* work-category-sec */
.work-category-sec {
  /* animation */
  > * {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
  h1 span {
    opacity: 0;
    transform: translateY(110%);
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .lb-txt span {
    opacity: 0;
    transform: translateY(110%);
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .p-box p span {
    opacity: 0;
    transform: translateY(110%);
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .video-box {
    video,
    .video {
      opacity: 0;
      transform: scale(1.11);
      transition: 1.05s ease;
    }
  }
  &.ani.active {
    &.not {
      h1 span,
      .lb-txt span,
      .p-box p span {
        transition: none;
      }
    }

    h1 {
      span {
        opacity: 1;
        transform: translateY(0);
      }
      &:nth-child(2) span {
        transition-delay: 0.1s;
      }
      &:nth-child(3) span {
        transition-delay: 0.2s;
      }
    }
    .lb-txt span {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.3s;
    }
    .p-box {
      p {
        span {
          opacity: 1;
          transform: translateY(0);
        }
        &:nth-child(1) span {
          transition-delay: 0.4s;
        }
        &:nth-child(2) span {
          transition-delay: 0.5s;
        }
        &:nth-child(3) span {
          transition-delay: 0.6s;
        }
        &:nth-child(4) span {
          transition-delay: 0.7s;
        }
        &:nth-child(5) span {
          transition-delay: 0.8s;
        }
      }
    }
    .video-box {
      video,
      .video {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  position: relative;

  .p-box {
    p {
      font-size: 0;
      overflow: hidden;
      display: block;

      span {
        display: inline-block;
      }
    }
  }

  .inner {
    position: absolute;
    padding-top: 4.9vw;
    width: 100%;
    z-index: 1;
  }

  h1 {
    margin: -0.22vw 0 -0.7vw;
    font-size: 0;
    display: block;
    overflow: hidden;

    span {
      opacity: 0;
      display: block;
      margin-top: -0.86vw;
      font-size: 6.613756613756614vw;
      line-height: 1.2;
      letter-spacing: -0.2vw;

      i {
        font-family: "Playfair Display";
      }
    }
  }

  .lb-txt {
    margin-top: 0.35vw;
    font-size: 0;
    display: block;
    overflow: hidden;

    span {
      display: inline-block;
      font-size: 1.322751322751323vw;
    }
  }

  .p-box {
    text-align: right;
    margin: 0.9vw 8.2vw 0 0;

    p {
      display: block;
      font-size: 0;
      overflow: hidden;
    }

    span {
      font-family: "Pretendard";
      font-size: 1.322751322751323vw;
      font-weight: 600;
      line-height: 1.6;
    }
  }

  .video-box {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 46.16402116402116%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 767px) {
  .work-category-sec {
    .inner {
      padding-top: 15.1vw;
    }

    h1 {
      > span {
        font-size: 8vw;
        letter-spacing: -0.24vw;
      }
    }

    .lb-txt {
      margin-top: 3.2vw;
      span {
        font-size: 2.933333333333333vw;
        letter-spacing: -0.1vw;
      }
    }

    .p-box {
      margin: 6.6vw 0 0 0;

      p {
        > span {
          font-size: 3.466666666666667vw;
          line-height: 1.68;
        }
      }
    }

    .video-box {
      padding-top: 103.2%;
    }
  }
}

/* scroll animation */
.ani {
  font-size: 0;
  overflow: hidden;
  display: block;

  &.not {
    > * {
      transition: none;
    }
  }

  > * {
    opacity: 0;
    transform: translateY(110%);
    display: inline-block;
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.ani.active {
  &.not {
    > * {
      transition: none;
    }
  }

  > * {
    opacity: 1;
    transform: translateY(0);
  }
}
.ani2 {
  font-size: 0;
  overflow: hidden;
  display: block;

  &.not {
    > * {
      transition: none;
    }
  }

  > span,
  > button,
  > div,
  > p,
  > ul {
    opacity: 0;
    transform: translateY(20%);
    display: inline-block;
    transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.ani2.active {
  > span,
  > button,
  > div,
  > p,
  > ul {
    opacity: 1;
    transform: translateY(0);
  }
}
.black {
  .line-top {
    &:before {
      background: #fff;
    }
  }
}
.line-top {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: #000;
    transition: width 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.ani.active {
    &.not {
      > * {
        transition: none;
      }
    }

    &:before {
      width: 100%;
    }
  }
}
.black {
  .line-bottom {
    &:after {
      background: #fff;
    }
  }
}
.line-bottom {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: #000;
    transition: width 1.05s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.ani.active {
    &.not {
      > * {
        transition: none;
      }
    }

    &:after {
      width: 100%;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes posY-110per-up {
  from {
    opacity: 0;
    transform: translateY(110%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes posY-20per-up {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes scale {
  from {
    opacity: 0;
    transform: scale(1.11);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes cover-up {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes posY-100per-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes swing-y {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1.8%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

/* button animation */
.black {
  .invert-btn {
    background: #000;

    &:before {
      background: #fff;
    }

    .btn-wrap {
      border: 1px solid #fff;
    }
  }
}
.invert-btn {
  position: relative;
  padding: 0.02vw;
  background: #fff;
  border-radius: 1.2vw;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    bottom: -1px;
    right: -1px;
    height: 0;
    background: #000;
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover:before {
    height: calc(100% + 2px);
  }

  .btn-wrap {
    display: inline-block;
    border-radius: 1.2vw;
    border: 1px solid #000;

    a {
      mix-blend-mode: difference;
      display: inline-block;
      padding: 0.24vw 0.7vw 0.2vw;
      font-size: 1.19047619047619vw;
      color: #fff;
    }
  }
}
@media screen and (max-width: 767px) {
  .invert-btn {
    padding: 0;
    border-radius: 3.5vw;

    .btn-wrap {
      border-radius: 3.5vw;

      span,
      a {
        padding: 1.7vw 2.6vw 1.2vw;
        font-size: 3.2vw;
      }
    }
  }
}

/* 드래그시 색 반전 */
::selection {
  background: #000;
  color: #fff;
}
::-moz-selection {
  background: #000;
  color: #fff;
}

/* 커서효과 */
html,
body {
  cursor: none !important;
}
*:hover {
  cursor: none !important;
}
button {
  cursor: none !important;
}
a {
  cursor: none !important;
}
body.cursor-default {
  cursor: none !important;

  *:hover {
    cursor: none !important;
  }

  .cursor {
    display: none;
  }
}
.cursor {
  position: fixed;
  top: -1000px;
  left: -1000px;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transform-origin: center;
  border-radius: 50%;
  background: #fff;
  z-index: 100;
  pointer-events: none;
  mix-blend-mode: difference;
  transition: all 150ms ease;
  transition-property: transform;

  &.clicked {
    transform: translate(-50%, -50%) scale(1.3) !important;
  }

  &.hover {
    transform: translate(-50%, -50%) scale(1.9);
    background: #2b79a6;
  }

  &.hidden {
    display: none;
  }
}

/* linear swiper */
.linear .swiper-wrapper {
  transition-timing-function: linear;
}

/* contact */
.contact .sec1 .bg {
  background: url(../../public/images/contact_bg.png) center top no-repeat;
  background-size: 100% auto !important;
}
.contact .sec1 .parallax-box .img1 .img {
  display: inline-block;
  width: 31.21693121693122vw;
  padding-top: 46.75925925925926vw;
  background: url(../../public/images/contact_img1.png) center no-repeat;
  background-size: 100% auto !important;
}
.contact .sec1 .parallax-box .img2 .img {
  display: inline-block;
  width: 57.40740740740741vw;
  padding-top: 35.38359788359788vw;
  background: url(../../public/images/contact_img2.png) center no-repeat;
  background-size: 100% auto !important;
}
@media screen and (max-width: 767px) {
  .contact .sec1 .parallax-box .img1 .img {
    width: 60.53333333333333vw;
    padding-top: 90.66666666666667vw;
  }
  .contact .sec1 .parallax-box .img2 .img {
    width: 80vw;
    padding-top: 49.06666666666667vw;
  }
}

/* vimeo */
.video {
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

/* work-creative */
.work-category-sec .video-box {
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
      box-sizing: border-box;
      width: calc(1vh * 177.7777777777778);
      width: calc(var(--vh, 1vh) * 177.7777777777778);
      height: 56.25vw;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

/* scrollbar */
@media screen and (max-width: 767px) {
  .scrollbar-track-y {
    width: 6px !important;
    background: none !important;
  }
  .scrollbar-thumb {
    width: 6px !important;
    background: #777 !important;
    opacity: 0.5;
  }
}

/* view more btn */
.view-more {
  margin: 5.7vw 0 9.5vw;
  width: 100%;
  text-align: center;

  > button {
    display: inline-block;
    font-size: 2.645502645502646vw;
    letter-spacing: -0.08vw;
  }
}
@media screen and (max-width: 767px) {
  .view-more {
    margin: 16vw 0 23vw;
    width: 100%;
    text-align: center;

    > button {
      display: inline-block;
      font-size: 5vw;
      letter-spacing: -0.08vw;
    }
  }
}

/* 문의하기 페이지 이동(구름) */
.cloud-box {
  position: fixed;
  bottom: 40px;
  right: calc(25 / 1512 * 100vw);
  width: 78px;
  transform-origin: right bottom;
  z-index: 1;
  transition: 0.2s;

  &.main-fix-bottom {
    bottom: 93px;
  }

  &.fix-bottom {
    bottom: calc(84px + 2.5vw);
  }

  .circle3 {
    position: absolute;
    top: 17%;
    left: 22%;
    width: 50%;
  }

  a {
    position: relative;
    transition: 0.2s;

    &:hover {
      transform: scale(1.15);
    }
  }
}

@media screen and (max-width: 1200px) {
  .cloud-box {
    bottom: 15px;
    width: 70px;

    &.main-fix-bottom {
      bottom: 78px;
    }

    &.fix-bottom {
      bottom: calc(67px + 2.5vw);
    }
  }
}

@media screen and (max-width: 767px) {
  .cloud-box {
    right: calc(47 / 1512 * 100vw);
    bottom: 10px;

    &.main-fix-bottom {
      bottom: 60px;
    }

    &.fix-bottom {
      bottom: 60px;
    }
  }
}

/* 모달 팝업 */
.modal-popup {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: All 0.3s ease-out;

  &.style2 {
    background: #fff;

    .inner {
      width: calc(620 / 1512 * 100vw);

      h1 {
        margin-left: calc(50 / 1512 * 100vw);
        padding: 0;

        img {
          top: calc(30 / 1512 * 100vw);
          right: 0;
        }
      }

      .p-box {
        margin-top: calc(65 / 1512 * 100vw);
        text-align: center;

        p {
          margin-top: 0;
        }
      }
    }
  }

  .inner {
    position: relative;
    padding: 0;
    width: calc(680 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #fff;
    backdrop-filter: blur(0.6613756613756614vw);
    overflow: hidden;

    h1 {
      position: relative;
      padding: calc(25 / 680 * 100%) calc(50 / 680 * 100%) 0;

      span {
        display: inline-block;
        position: relative;
        font-size: calc(156 / 1512 * 100vw);
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: calc(-4.68 / 1512 * 100vw);
        z-index: 1;
      }
      img {
        position: absolute;
        top: calc(57 / 1512 * 100vw);
        right: calc(62 / 1512 * 100vw);
        width: calc(97 / 1512 * 100vw);
      }
    }

    .p-box {
      margin-top: calc(-31 / 1512 * 100vw);
      padding: 0 calc(62 / 1512 * 100vw);

      p {
        margin-top: calc(14 / 1512 * 100vw);
        font-family: "Pretendard";
        font-size: calc(20 / 1512 * 100vw);
        font-weight: 600;
        letter-spacing: calc(-0.6 / 1512 * 100vw);

        &.step1 {
          line-height: calc(30 / 1512 * 100vw);
        }

        &.step2 {
          line-height: calc(22 / 1512 * 100vw);
        }
      }
    }

    .btn-box {
      margin-top: calc(55 / 1512 * 100vw);
      padding: 0 calc(55 / 1512 * 100vw) calc(35 / 1512 * 100vw);
      display: flex;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(275 / 1512 * 100vw);
        height: calc(60 / 1512 * 100vw);
        border-radius: calc(49 / 1512 * 100vw);
        border: 1px solid #000;
        transition: 0.5s;

        span {
          text-align: center;
          font-family: "Pretendard";
          font-size: calc(24 / 1512 * 100vw);
          font-weight: 700;
          line-height: normal;
          letter-spacing: calc(-0.72 / 1512 * 100vw);
          transition: 0.5s;
        }

        &:hover {
          background: #000;

          span {
            color: #fff;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .modal-popup {
    &.style2 {
      top: 44px;
      height: calc(100% - 44px);

      .inner {
        margin-top: calc(-42 / 375 * 100vw);
        width: calc(235 / 375 * 100vw);

        h1 {
          margin-left: calc(10 / 375 * 100vw);

          img {
            width: calc(43 / 375 * 100vw);
            top: calc(8.35 / 375 * 100vw);
          }
        }

        .p-box {
          margin-top: calc(17 / 375 * 100vw);
        }
      }
    }

    .inner {
      width: calc(283 / 375 * 100vw);
      border-radius: calc(10 / 375 * 100vw);
      backdrop-filter: blur(2.666666666666667vw);

      h1 {
        padding: calc(22 / 283 * 100%) calc(28 / 283 * 100%) 0;

        span {
          font-size: calc(60 / 375 * 100vw);
          line-height: 1.2;
          letter-spacing: calc(-1.8 / 375 * 100vw);
        }
        img {
          top: calc(11.35 / 375 * 100vw);
          right: calc(5.62 / 375 * 100vw);
          width: calc(87.33 / 375 * 100vw);
        }
      }

      .p-box {
        margin-top: calc(-6 / 375 * 100vw);
        padding: 0 calc(30 / 375 * 100vw);

        p {
          margin-top: calc(12 / 375 * 100vw);
          font-size: calc(15 / 375 * 100vw);
          letter-spacing: calc(-0.45 / 375 * 100vw);

          &.step1 {
            line-height: calc(23 / 375 * 100vw);
          }

          &.step2 {
            line-height: calc(16 / 375 * 100vw);
          }
        }
      }

      .btn-box {
        margin-top: calc(34 / 375 * 100vw);
        padding: 0 calc(30 / 375 * 100vw) calc(30 / 375 * 100vw);

        a {
          width: calc(104 / 375 * 100vw);
          height: calc(38 / 375 * 100vw);
          border-radius: calc(49 / 375 * 100vw);

          span {
            text-align: center;
            font-size: calc(20 / 375 * 100vw);
            letter-spacing: calc(-0.6 / 375 * 100vw);
          }
        }
      }
    }
  }
}
