.our-team-personal {
  /* animation */
  .sec1 {
    .p-box p {
      transition-delay: 0.1s;
    }

    .imoji {
      opacity: 0;

      &.ani {
        transform: translateY(12%);
        transition-delay: 0.1s;
        transition-duration: 0.5s;
        transition-timing-function: ease;

        @media screen and (max-width: 767px) {
          transition-delay: 0.2s;
        }
      }

      &.ani.active {
        opacity: 1;
        transform: translateY(0);

        img {
          opacity: 1;
          transform: none;
          transition: none;
          animation: swing-y 2s ease-in-out infinite both;
        }
      }

      &.ani.active.not {
        transition: none;

        img {
          animation: none;
        }
      }
    }

    .carrer-box {
      &.line-top:before {
        transition-delay: 0.2s;

        @media screen and (max-width: 767px) {
          transition-delay: 0.3s;
        }
      }

      .carrer-lists {
        > li {
          transition-delay: 0.2s;

          @media screen and (max-width: 767px) {
            transition-delay: 0.3s;
          }
        }
      }

      .field-lists li span {
        transition-delay: 0.2s;

        @media screen and (max-width: 767px) {
          transition-delay: 0.4s;
        }
      }
    }
  }
  .sec2 {
    .project-experience {
      ul li {
        a:hover {
          .arrow {
            span {
              &.step1 {
                transform: translate3d(60%, -60%, 0);
                opacity: 0;
                transition-delay: 0s;
              }

              &.step2 {
                transform: translate3d(0, 0, 0) rotate(0deg);
                opacity: 1;
                transition-delay: 0.1s;
              }
            }
          }

          img {
            transform: scale(1.11) !important;
          }

          .video {
            transform: scale(1.11) !important;
          }
        }

        .text-box > ul > li {
          font-size: 0;
          display: block;
          overflow: hidden;

          &:nth-child(1) span {
            transition-delay: 0.1s;
          }
          &:nth-child(2) span {
            transition-delay: 0.2s;
          }
          &:nth-child(3) span {
            transition-delay: 0.3s;
          }

          > span {
            opacity: 0;
            display: inline-block;
            transform: translateY(110%);
            transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
          }
        }

        .img-box {
          overflow: hidden;
          img {
            opacity: 0;
            transform: scale(1.11);
            transition: 1.05s ease;
          }
        }

        .year {
          font-size: 0;
          display: block;
          overflow: hidden;

          span {
            opacity: 0;
            display: inline-block;
            transform: translateY(110%);
            transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
          }
        }

        &.ani.active {
          .text-box > ul > li {
            > span {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .img-box {
            img {
              opacity: 1;
              transform: scale(1);
            }

            .video {
              opacity: 1;
              transform: scale(1);
            }
          }

          .year span {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }

  .fixed-text {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    align-items: center;

    &.pc {
      display: flex !important;
    }

    z-index: 3;

    .inner {
      margin-top: -14.7vw;

      h2 {
        text-align: left;
        span {
          padding-right: 1vw;
          font-size: 7.936507936507937vw;
          font-weight: 600;
          line-height: 1.3;
          letter-spacing: -0.25vw;
        }
      }

      .p-box {
        padding-top: 0.3vw;
        text-align: left;

        p {
          display: block;
          font-size: 1.984126984126984vw;
          font-weight: bold;
          line-height: 1.55;
          letter-spacing: -0.06vw;

          &.ko {
            font-family: "Pretendard";
          }
        }
      }
    }
  }

  .sec1 {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;

    .inner {
      position: relative;
      width: 100%;
      margin-top: -0.5vw;
      text-align: right;
    }

    .imoji {
      position: relative;
      display: inline-block;
      margin: -0.55vw 5vw 0 0;
      z-index: 1;

      img {
        width: 42.98941798941799vw;
      }
    }

    .carrer-box {
      margin-top: -15vw;
      padding-top: 1.3vw;
      display: flex;
      overflow: visible;
      text-align: left;

      > .carrer-lists,
      .field-lists {
        opacity: 1;
        transform: none;
        transition: none;
      }

      .carrer-lists {
        li {
          display: block;

          .campany {
            display: inline-block;
            vertical-align: middle;
            font-size: 1.322751322751323vw;
            font-weight: 500;
            letter-spacing: -0.04vw;
          }
          .year {
            position: relative;
            margin: -0.8vw 0 0 0.3vw;
            display: inline-block;
            vertical-align: middle;
            font-size: 0.8vw;
            font-weight: 500;
            letter-spacing: -0.05vw;

            // &:after {
            //   content: "-";
            //   display: inline-block;
            //   margin-left: 0.2vw;
            // }
          }
        }
      }

      .field-lists {
        margin: 0.1vw 0 0 4.6vw;

        ul {
          margin: -0.3306878306878307vw;

          li {
            padding: 0.3306878306878307vw;
            display: inline-block;
            float: left;

            &:nth-child(3n + 1) {
              clear: both;
            }

            span {
              display: inline-block;
              padding: 0 0.71vw 0.1vw;
              font-size: 1.124338624338624vw;
              font-weight: 500;
              letter-spacing: -0.03vw;
              background: #fff;
              border-radius: 1.058201058201058vw;
            }
          }
        }
      }
    }
  }

  .sec2 {
    padding: 13.55vw 0 5.2vw;

    .inner {
      text-align: right;

      .cont-wrap {
        display: inline-block;
        width: 64.02116402116402vw;
        text-align: left;

        h2 {
          span {
            font-size: 4.62962962962963vw;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: -0.14vw;
          }
        }

        .project-experience {
          margin-top: 1.1vw;

          > ul {
            opacity: 1;
            transform: none;
            transition: none;
            display: block;

            > li {
              position: relative;
              padding: 0 0 6vw;

              > * {
                opacity: 1;
                transform: none;
                transition: none;
                display: inline-block;
                vertical-align: top;
              }

              > a {
                margin-top: 1.587301587301587vw;

                .text-box {
                  margin-top: 0.2vw;

                  ul {
                    li {
                      > span {
                        display: inline-block;
                        font-size: 1.124338624338624vw;
                        line-height: 1.28;
                        letter-spacing: -0.035vw;

                        .arrow {
                          margin: -0.2vw 0.7vw 0 0.67vw;
                          width: 0.8498677248677249vw;
                          height: 0.8544973544973545vw;

                          span {
                            background: url(../../../public/images/our_team_personal_arrow.png) center no-repeat;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .view-more {
            margin: 0 0 1.2vw;
          }

          .img-box {
            position: relative;
            float: right;
            margin: 1.587301587301587vw 8.2vw 0 0;

            &.type1 {
              width: 23.01587301587302vw;

              a {
                padding-top: 74.71264367816092%;
              }
            }

            &.type2 {
              width: 23.01587301587302vw;

              a {
                padding-top: 129.3103448275862%;
              }
            }

            &.type3 {
              width: 31.21693121693122vw;

              a {
                padding-top: 55.08474576271186%;
              }
            }

            > a {
              position: relative;
              width: 100%;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .video {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1.11);
                transition: 1.05s ease;

                iframe {
                  position: absolute;
                  top: -2% !important;
                  right: -2% !important;
                  bottom: -2% !important;
                  left: -2% !important;
                  width: 104% !important;
                  height: 104% !important;
                }
              }
            }
          }

          .year {
            position: absolute;
            top: 1.15vw;
            right: 0;

            span {
              display: inline-block;
              font-size: 1.124338624338624vw;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .our-team-personal {
    position: relative;

    .fixed-text {
      position: absolute;
      height: auto;

      &.pc {
        display: none !important;
      }

      // &.mo{
      //   display: block;
      // }

      .inner {
        margin-top: 0;
        padding-top: 19.9vw;

        h2 {
          span {
            font-size: 13.33333333333333vw;
            letter-spacing: -0.4vw;
          }
        }

        .p-box {
          padding-top: 0.3vw;

          p {
            font-size: 5.333333333333333vw;
            line-height: 1.64;
            letter-spacing: -0.15vw;
          }
        }
      }
    }

    .wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .sec1 {
      display: block;
      padding: 20vw 0 13.863vw;
      height: auto;

      .inner {
        margin-top: 0;
      }

      .imoji {
        margin: 43.5vw 0 0 0;

        img {
          width: 76.53333333333333vw;
        }
      }

      .carrer-box {
        margin-top: -1.999vw;
        padding-top: 2.3vw;
        display: block;

        .carrer-lists {
          width: 100%;

          li {
            .campany {
              font-size: 4vw;
              line-height: 1.6;
              letter-spacing: -0.12vw;
            }
            .year {
              margin: -2vw 0 0 0.9vw;
              font-size: 2.7vw;
            }
          }
        }

        .field-lists {
          margin: 5vw 0 0;

          ul {
            margin: -1.066666666666667vw;

            li {
              padding: 1.066666666666667vw;

              &:nth-child(3n + 1) {
                clear: both;
              }

              span {
                padding: 0.2vw 2.22vw 0.242vw;
                font-size: 3.466666666666667vw;
                letter-spacing: -0.09vw;
                border-radius: 3.466666666666667vw;
              }
            }
          }
        }
      }
    }

    .sec2 {
      padding: 13.55vw 0 18.2vw;

      .inner {
        text-align: left;

        .cont-wrap {
          width: 100%;
          text-align: left;

          h2 {
            span {
              font-size: 6.666666666666667vw;
              letter-spacing: -0.2vw;
            }
          }

          .project-experience {
            margin-top: 2.6vw;

            > ul {
              > li {
                padding: 0 0 21.3vw;

                > a {
                  margin-top: 4vw;
                  width: 100%;

                  .text-box {
                    ul {
                      li {
                        > span {
                          font-size: 4vw;
                          letter-spacing: -0.12vw;

                          .arrow {
                            margin: -0.2vw 0.7vw 0 2.05vw;
                            width: 2.336vw;
                            height: 2.4vw;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .img-box {
              float: none;
              margin: 5.1vw 0 0;

              &.type1 {
                float: right;
                width: 60.53333333333333vw;

                a {
                  padding-top: 72.24669603524229%;
                }
              }

              &.type2 {
                width: 60.53333333333333vw;

                a {
                  padding-top: 129.5154185022026%;
                }
              }

              &.type3 {
                width: 92.53333333333333vw;

                a {
                  padding-top: 55.3314121037464%;
                }
              }
            }

            .year {
              display: none;
            }
          }
        }
      }
    }
  }
}
