.contact {
  position: relative;

  /* animation */
  .sec1 {
    .bg {
      img {
        opacity: 0;
        transform: scale(1.11);
        transition: 1.05s ease;
      }

      &.ani.active {
        img {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  .contact-lists {
    > li {
      h3 > span {
        opacity: 0;
        transform: translateY(110%);
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }

      .content li {
        p,
        a {
          opacity: 0;
          transform: translateY(110%);
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }

      &.ani.active {
        &.not {
          h3 > span {
            transition: none;
          }
          .content li {
            p,
            a {
              transition: none;
            }
          }
        }

        h3 > span {
          opacity: 1;
          transform: translateY(0);
        }

        .content li {
          p,
          a {
            opacity: 1;
            transform: translateY(0);
          }

          &:nth-child(1) {
            p,
            a {
              transition-delay: 0.1s;
            }
          }
          &:nth-child(2) {
            p,
            a {
              transition-delay: 0.2s;
            }
          }
          &:nth-child(3) {
            p,
            a {
              transition-delay: 0.3s;
            }
          }
          &:nth-child(4) {
            p,
            a {
              transition-delay: 0.4s;
            }
          }
          &:nth-child(5) {
            p,
            a {
              transition-delay: 0.5s;
            }
          }
        }
      }
    }
  }

  .sec1 {
    position: relative;
    height: 120vw;
    overflow: hidden;

    .bg {
      position: absolute !important;
      top: -15%;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      h1 {
        margin: 4.9vw 0 15.2vw 0.12vw;
        span {
          position: relative;
          font-size: 9.920634920634921vw;
          line-height: 1;
          letter-spacing: -0.29vw;
          z-index: 1;
        }
      }
    }

    .parallax-box {
      position: relative;
      margin-top: -1.45vw;
      height: 100%;

      .img1 {
        position: absolute;
        top: 9.920634920634921vw;
        right: 9.788359788359788vw;

        img {
          width: 31.21693121693122vw !important;
        }

        .txt {
          margin-top: 1.4vw;
        }
      }

      .img2 {
        position: absolute;
        top: 45.2vw;
        left: 0vw;

        .txt.top {
          margin: 0 0 0.9vw 1.7vw;
        }

        .txt.bottom {
          margin-top: 0.5vw;
          text-align: right;
        }

        img {
          width: 57.40740740740741vw !important;
        }
      }

      .txt {
        > span {
          display: inline-block;
          font-size: 0.8897883597883598vw;
          line-height: 1.46;
          letter-spacing: -0.028vw;
          color: #fff;

          &::selection {
            background: #fff;
            color: #000;
          }
          &::-moz-selection {
            background: #fff;
            color: #000;
          }

          i {
            color: #fff;

            &::selection {
              background: #fff;
              color: #000;
            }
            &::-moz-selection {
              background: #fff;
              color: #000;
            }
          }
        }
      }

      .white-txt {
        position: absolute;
        top: 82vw;
        right: 0;

        .txt {
          padding-right: 1.73vw;

          > span {
            text-align: right;
            display: inline-block;
            font-family: "Pretendard";
            font-size: 5.291005291005291vw;
            font-weight: 500;
            color: #fff;
            line-height: 1.325;
            letter-spacing: -0.16vw;

            .noto-font {
              color: #fff;

              &::selection {
                background: #fff;
                color: #000;
              }
              &::-moz-selection {
                background: #fff;
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .sec2 {
    padding-bottom: 2.6vw;

    .contact-lists {
      > li {
        display: flex;
        height: 18.18783068783069vw;

        .icon {
          background-size: cover !important;
        }

        &:nth-child(1) {
          .icon {
            margin: 0 0 0 0.15vw;
            width: 3.306878306878307vw;
            height: 3.373015873015873vw;
            background: url(../../../public/images/contact_icon1.png) center no-repeat;
          }
        }

        &:nth-child(2) {
          .icon {
            margin: 0 -1vw -0.4vw -0.55vw;
            width: 4.695767195767196vw;
            height: 4.828042328042328vw;
            background: url(../../../public/images/contact_icon2.png) center no-repeat;
          }
        }

        &:nth-child(3) {
          .icon {
            margin: 0 -1vw -0.4vw -0.8vw;
            width: 4.232804232804233vw;
            height: 4.034391534391534vw;
            background: url(../../../public/images/contact_icon3.png) center no-repeat;
          }
        }

        &:nth-child(4) {
          .icon {
            margin: 0 0.5vw -0.1vw -0.25vw;
            width: 3.835978835978836vw;
            height: 3.968253968253968vw;
            background: url(../../../public/images/contact_icon4.png) center no-repeat;
          }
        }

        .title {
          width: 33.9%;
          transition: none !important;

          h3 {
            overflow: hidden;
            font-size: 0;
            line-height: 0;

            span {
              display: inline-block;
              font-size: 4.62962962962963vw;
              line-height: 1.32;
              letter-spacing: -0.14vw;
            }
          }
        }

        .content {
          padding-top: 1.4vw;
          width: 38%;

          li {
            display: block;
            overflow: hidden;
            font-size: 0;

            p,
            a {
              display: inline-block;
              font-size: 1.653439153439153vw;
              letter-spacing: -0.065vw;
              line-height: 1.3;

              &.ptd-font {
                font-weight: 500;
                font-size: 1.521164021164021vw;
                letter-spacing: -0.047vw;
              }
            }

            .b_line {
              position: relative;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0.05vw;
                width: 100%;
                height: 3px;
                background: #000;
              }
            }
          }
        }
      }
    }

    .arrow-txt {
      margin: 2.5vw -1vw 0 0.1vw;

      .text-wrap {
        display: flex;
        align-items: center;
      }

      .txt {
        margin: 0 0.5vw 0 -1.1vw;
        text-transform: uppercase;
        font-size: 13.22751322751323vw;
        font-weight: 400;
        line-height: 13vw;
        letter-spacing: -0.48vw;
      }

      .bracket {
        padding-bottom: 0.5vw;
        width: 16.87103174603175vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.4s ease-in-out;

        .pd-font {
          font-size: 11.9047619047619vw;
          line-height: 13vw;
        }

        .arrow {
          margin: 1.1vw 1.2vw 0 1vw;
          width: 7.671957671957672vw;
          height: 7.671957671957672vw;
          background: url(../../../public/images/contact_arrow.png) center no-repeat;
          background-size: 100% auto;
        }
      }

      &:hover {
        .bracket {
          width: calc(499 / 1512 * 100%);
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .contact .sec2 .contact-lists > li .content li .b_line:before {
    height: 2px;
  }
}
@media screen and (max-width: 767px) {
  .contact .sec2 .contact-lists > li .content li .b_line:before {
    bottom: 0.7vw;
    height: 3px;
  }
  .contact {
    .sec1 {
      height: 348vw;

      .bg {
        top: 0;
        left: -52%;
        width: 204%;
      }

      .inner {
        h1 {
          margin: 14.7vw 0 0 0.5vw;
          span {
            font-size: 10.66666666666667vw;
            letter-spacing: -0.31vw;
          }
        }
      }

      .parallax-box {
        margin-top: 4.1vw;

        .img1 {
          top: 32.26666666666667vw;
          right: 3.733333333333333vw;

          img {
            width: 60.53333333333333vw !important;
          }

          .txt {
            margin-top: 1.9vw;
          }
        }

        .img2 {
          top: 170.8vw;
          left: 0vw;

          .txt.top {
            margin: 0 0 3vw 3.7vw;
          }
          .txt.bottom {
            margin-top: 1.5vw;
          }

          img {
            width: 80vw !important;
          }
        }

        .txt {
          > span {
            font-size: 2.933333333333333vw;
            line-height: 1.21;
            letter-spacing: -0.09vw;
          }
        }

        .white-txt {
          top: 236.2vw;

          .txt {
            padding-right: 3.9vw;

            > span {
              font-size: 8vw;
              line-height: 1.53;
              letter-spacing: -0.23vw;
            }
          }
        }
      }
    }

    .sec2 {
      padding-bottom: 0;

      .contact-lists {
        > li {
          display: block;
          padding-bottom: 0;
          height: 71.73333333333333vw;

          &:first-child {
            padding-bottom: 0;
          }

          &:last-child {
            height: 60.7vw;
          }

          &:nth-child(1) {
            .icon {
              margin: 0 0 -0.2vw 0.3vw;
              width: 8.8vw;
              height: 9.066666666666667vw;
            }
          }

          &:nth-child(2) {
            .icon {
              margin: 0 -1.8vw -0.7vw -0.55vw;
              width: 9.6vw;
              height: 9.866666666666667vw;
            }
          }

          &:nth-child(3) {
            .icon {
              margin: 0 -0.8vw -0.4vw -2.3vw;
              width: 8.8vw;
              height: 8.266666666666667vw;
            }
          }

          &:nth-child(4) {
            .icon {
              margin: 0 0.8vw -1vw -1vw;
              width: 9.066666666666667vw;
              height: 9.333333333333333vw;
            }
          }

          .title {
            width: 100%;

            h3 {
              padding-left: 0.43vw;

              span {
                font-size: 10.66666666666667vw;
                line-height: 1.4;
                letter-spacing: -0.33vw;
              }
            }
          }

          .content {
            padding: 1.1vw 0 0 16vw;
            width: 100%;

            li {
              .address {
                font-size: 4vw;
                letter-spacing: -0.16vw;
                line-height: 1.56;

                &.ptd-font {
                  font-size: 4vw;
                  letter-spacing: -0.13vw;
                  line-height: 1.32;
                }
              }
              p,
              a {
                font-size: 4.533333333333333vw;
                letter-spacing: -0.17vw;
                line-height: 1.5;

                &.ptd-font {
                  font-size: 4.533333333333333vw;
                  letter-spacing: -0.17vw;
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
      .arrow-txt {
        margin: 0 calc(-10 / 767 * 100%) 0 0;

        &:hover {
          .bracket {
            width: calc(227 / 757 * 100%);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .contact .sec2 .contact-lists > li .content li .b_line:before {
    height: 2px;
  }
}
