.request {
  position: relative;

  .sec1 {
    h1 {
      margin: 5vw 0 0.52vw;
      span {
        font-size: calc(250 / 1512 * 100vw);
        line-height: calc(200 / 1512 * 100vw);
        letter-spacing: calc(-9 / 1512 * 100vw);
        text-transform: uppercase;
      }
    }

    .p-box-wrap {
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      padding: 0 1.4vw;

      .p-box {
        &.step1 {
          p {
            margin-top: 0.3vw;

            span {
              line-height: calc(24 / 1512 * 100vw);
              letter-spacing: calc(-0.8 / 1512 * 100vw);
            }
          }
        }
        &.step2 {
          margin-right: 15.8vw;
        }

        p {
          span {
            font-family: "Pretendard";
            font-size: calc(20 / 1512 * 100vw);
            font-weight: 600;
            line-height: calc(28 / 1512 * 100vw);
            letter-spacing: calc(-0.6 / 1512 * 100vw);
          }

          .img1 {
            margin-left: 0.2vw;
            vertical-align: text-bottom;
            width: calc(28 / 1512 * 100vw);
          }
        }
      }
    }
  }

  .sec2 {
    padding: 4.78vw 0 5.44vw;

    .inner {
      padding: 0 calc(148 / 1512 * 100vw);

      form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 calc(24 / 1512 * 100vw);
      }

      .request-lists {
        flex: 1;

        > li {
          padding: 1.38vw 0 1.25vw;

          label {
            vertical-align: middle;
            font-family: "Pretendard";
            font-size: calc(20 / 1512 * 100vw);
            font-weight: 700;
            line-height: normal;
            letter-spacing: calc(-0.6 / 1512 * 100vw);
          }

          .input-box-wrap {
            display: flex;

            .input-box {
              margin-top: 0.65vw;
              width: 8.2vw;
              padding: 0 0.8vw;
              border-left: 1px solid #ccc;

              &:last-child {
                flex: 1;
              }

              input {
                margin-top: 0;
                font-size: calc(14 / 1512 * 100vw);
                font-weight: 500;

                &::placeholder {
                  font-size: calc(14 / 1512 * 100vw);
                  font-weight: 500;
                  letter-spacing: calc(-0.42 / 1512 * 100vw);
                }
              }
            }
          }

          .input-box {
            width: 100%;

            textarea {
              resize: none;
            }

            input,
            textarea {
              margin-top: 0.65vw;
              display: block;
              width: 100%;
              height: calc(24 / 1512 * 100vw);
              font-family: "Pretendard";
              font-size: calc(20 / 1512 * 100vw);
              font-weight: 700;
              line-height: calc(24 / 1512 * 100vw);
              letter-spacing: calc(-0.6 / 1512 * 100vw);

              &::placeholder {
                color: rgba(0, 0, 0, 0.3);
                font-family: "Pretendard";
                font-size: calc(16 / 1512 * 100vw);
                font-weight: 500;
                letter-spacing: calc(-0.48 / 1512 * 100vw);
              }
            }
          }

          .label-box {
            display: flex;

            .file-input {
              position: relative;
              margin-left: 0.9vw;
              padding-right: 2vw;
              display: flex;
              align-items: center;

              &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-56%);
                width: calc(27 / 1512 * 100vw);
                height: calc(21 / 1512 * 100vw);
                background: url(../../../public/images/request_icon2.png) no-repeat;
                background-size: 100% auto;
              }

              span {
                display: inline-block;
                line-height: calc(16 / 1512 * 100vw);
                font-family: "Pretendard";
                font-size: calc(15 / 1512 * 100vw);
                font-weight: 500;
                letter-spacing: calc(-0.45 / 1512 * 100vw);
                border-bottom: 1px solid #000;
              }

              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 100px;
                opacity: 0;
                cursor: none;

                &::-webkit-file-upload-button {
                  cursor: none;
                }
              }
            }
          }

          .radio-lists {
            margin-top: 0.8vw;

            li {
              position: relative;
              display: inline-block;
              margin: 0 calc(11 / 1512 * 100vw) calc(11 / 1512 * 100vw) 0;

              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                opacity: 0;

                &:checked + label {
                  background: #000;

                  span {
                    color: #fff;
                  }
                }
              }

              label {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.152vw 0.5vw 0.101vw;
                width: calc(113 / 1512 * 100vw);
                line-height: unset;
                border-radius: calc(39 / 1512 * 100vw);
                background: #f3f3f3;
                transition: 0.5s;
                cursor: pointer;

                &.width {
                  width: calc(50 / 1512 * 100vw);
                }

                span {
                  font-family: "Pretendard";
                  font-size: calc(16 / 1512 * 100vw);
                  color: rgba(0, 0, 0, 0.3);
                  font-weight: 500;
                  letter-spacing: calc(-0.48 / 1512 * 100vw);
                }
              }
            }
          }

          .file-lists {
            margin-top: 0.76vw;

            li {
              display: inline-block;
              margin: 0 calc(14 / 1512 * 100vw) calc(3 / 1512 * 100vw) 0;

              span {
                display: inline-block;
                vertical-align: middle;

                &.txt {
                  margin-right: calc(4 / 1512 * 100vw);
                  font-family: "Pretendard";
                  font-size: calc(14 / 1512 * 100vw);
                  font-weight: 500;
                  letter-spacing: calc(-0.42 / 1512 * 100vw);
                }

                &.delete-file {
                  width: calc(20 / 1512 * 100vw);
                  height: calc(20 / 1512 * 100vw);
                  background: url(../../../public/images/request_remove_file_btn.png) no-repeat;
                  background-size: 100% auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .sec3 {
    position: relative;
    width: 100%;
    height: calc(140 / 1512 * 100vw);
    background: #fff;

    .inner {
      padding: calc(33 / 1512 * 100vw) 0;
      padding-left: calc(768 / 1512 * 100vw);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 0;
        background: #000;
        transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);
      }

      &:hover,
      &.hover {
        &:before {
          height: 100%;
        }

        .icon {
          transform-origin: center;
          transform: rotate(15deg);
        }
      }

      > div {
        display: inline-block;
        vertical-align: middle;
        margin-right: calc(12 / 1512 * 100vw);

        &.arrow {
          mix-blend-mode: difference;
          position: relative;
          width: calc(72 / 1512 * 100vw);
          height: calc(72 / 1512 * 100vw);
          border-radius: 100%;
          background: #fff;

          &:before {
            content: "";
            mix-blend-mode: difference;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../../../public/images/request_arrow_w.png) no-repeat;
            background-size: 100% auto;
          }
        }

        &.text {
          mix-blend-mode: difference;
          padding: 0.4vw 1.5264vw 0.25vw;
          height: calc(74 / 1512 * 100vw);
          border-radius: calc(46 / 1512 * 100vw);
          border: 0.1984126984126984vw solid #fff;

          span {
            mix-blend-mode: difference;
            font-family: "Pretendard";
            font-size: calc(50 / 1512 * 100vw);
            color: #fff;
            font-weight: 700;
            line-height: calc(60 / 1512 * 100vw);
            letter-spacing: calc(-1.5 / 1512 * 100vw);
          }
        }

        &.icon {
          position: relative;
          width: calc(100 / 1512 * 100vw);
          transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 6;

    &.on {
      display: flex;
    }

    img {
      width: calc(200 / 1512 * 100%);
    }
  }

  @media screen and (max-width: 767px) {
    .sec1 {
      .inner {
        padding: 0 3.6vw;
      }

      h1 {
        margin: 13.75vw 0 0.52vw;
        span {
          font-size: calc(70 / 375 * 100vw);
          line-height: calc(70 / 375 * 100vw);
          letter-spacing: calc(-2.5 / 375 * 100vw);
        }
      }

      .p-box-wrap {
        display: block;
        padding: 0;

        .p-box {
          &.step1 {
            p {
              margin-top: 1.8vw;

              span {
                line-height: calc(19 / 375 * 100vw);
                letter-spacing: calc(-0.55 / 375 * 100vw);
              }
            }
          }
          &.step2 {
            margin: 6.5vw 15.8vw 0 0;
          }

          p {
            span {
              font-size: calc(15 / 375 * 100vw);
              line-height: calc(23.7 / 375 * 100vw);
              letter-spacing: calc(-0.45 / 375 * 100vw);
            }

            .img1 {
              margin-left: -0.2vw;
              width: calc(20 / 375 * 100vw);
            }
          }
        }
      }
    }

    .sec2 {
      padding: 17.1vw 0 9.5vw;

      .inner {
        padding: 0 calc(14 / 375 * 100vw);

        form {
          display: block;
        }

        .request-lists {
          flex: 1;

          > li {
            padding: 4.55vw 0;

            &.style1 {
              padding-bottom: 2.3vw;
            }

            label {
              font-size: calc(15 / 375 * 100vw);
              letter-spacing: calc(-0.45 / 375 * 100vw);
            }

            .input-box-wrap {
              margin: 0.4vw 0 -1.6vw;
              display: grid;
              grid-template-columns: repeat(3, 1fr);

              .input-box {
                margin-top: 3.8vw;
                width: auto;
                padding: 0 2.2vw;

                input {
                  margin-top: 0;
                  font-size: calc(14 / 375 * 100vw);
                  font-weight: 500;
                  height: calc(20 / 375 * 100vw);
                  line-height: calc(20 / 375 * 100vw);

                  &::placeholder {
                    font-size: calc(14 / 375 * 100vw);
                    letter-spacing: calc(-0.42 / 375 * 100vw);
                  }
                }
              }
            }

            .input-box {
              input,
              textarea {
                margin-top: 3.1vw;
                height: calc(17 / 375 * 100vw);
                font-size: calc(14 / 375 * 100vw);
                font-weight: 500;
                line-height: calc(17 / 375 * 100vw);
                letter-spacing: calc(-0.6 / 375 * 100vw);

                &::placeholder {
                  font-size: calc(14 / 375 * 100vw);
                  letter-spacing: calc(-0.48 / 375 * 100vw);
                }
              }
            }

            .label-box {
              display: flex;

              .file-input {
                margin-left: 2.6vw;
                padding-right: calc(21 / 375 * 100vw);

                &:before {
                  transform: translateY(-56%);
                  width: calc(18 / 375 * 100vw);
                  height: calc(14 / 375 * 100vw);
                }

                span {
                  margin-top: -0.15vw;
                  line-height: calc(14 / 375 * 100vw);
                  font-size: calc(13 / 375 * 100vw);
                  letter-spacing: calc(-0.39 / 375 * 100vw);
                }
              }
            }

            .radio-lists {
              margin-top: 3vw;

              li {
                margin: 0 calc(12 / 375 * 100vw) calc(12 / 375 * 100vw) 0;

                label {
                  padding: 0.3vw 2.308vw 0.1vw;
                  width: auto;
                  border-radius: calc(39 / 375 * 100vw);

                  &.width {
                    width: auto;
                  }

                  span {
                    font-size: calc(13 / 375 * 100vw);
                    letter-spacing: calc(-0.48 / 375 * 100vw);
                  }
                }
              }
            }

            .file-lists {
              margin-top: 2.9vw;

              li {
                margin: 0 calc(12 / 375 * 100vw) calc(4 / 375 * 100vw) 0;

                span {
                  &.txt {
                    margin-right: calc(4 / 375 * 100vw);
                    font-size: calc(12 / 375 * 100vw);
                    letter-spacing: calc(-0.36 / 375 * 100vw);
                  }

                  &.delete-file {
                    width: calc(20 / 375 * 100vw);
                    height: calc(20 / 375 * 100vw);
                  }
                }
              }
            }
          }
        }
      }
    }

    .sec3 {
      padding: 0 calc(17 / 375 * 100vw) calc(28 / 375 * 100vw);
      height: auto;
      background: none;

      .inner {
        padding: 0;
        display: inline-block;

        &:before {
          display: none;
        }

        > div {
          margin-right: calc(6 / 375 * 100vw);

          &.arrow {
            width: calc(43 / 375 * 100vw);
            height: calc(43 / 375 * 100vw);
            background: #000;

            &:before {
              mix-blend-mode: unset;
              background: url(../../../public/images/request_arrow_w.png) no-repeat;
              background-size: 100% auto;
            }
          }

          &.text {
            mix-blend-mode: unset;
            margin-right: calc(4 / 375 * 100vw);
            padding: 0.65vw 3.4vw 0.1vw;
            height: calc(43 / 375 * 100vw);
            border-radius: calc(46 / 375 * 100vw);
            border: 0.5333333333333333vw solid #000;

            span {
              mix-blend-mode: unset;
              font-size: calc(28 / 375 * 100vw);
              line-height: calc(34 / 375 * 100vw);
              letter-spacing: calc(-0.84 / 375 * 100vw);
              color: #000;
            }
          }

          &.icon {
            width: calc(67 / 375 * 100vw);
            transform: unset !important;
          }
        }
      }
    }
  }
}
