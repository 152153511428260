.about {
  height: calc(var(--vh, 1vh) * 100);

  /* animation */
  .sec1 {
    h1 {
      &.step2 span {
        transition-delay: 0.1s;
      }
      &.step3 span {
        transition-delay: 0.2s;
      }
      &.step4 span {
        transition-delay: 0.3s;
      }
      &.step5 span {
        transition-delay: 0.4s;
      }
      &.step6 span {
        transition-delay: 0.5s;
      }
      &.step7 span {
        transition-delay: 0.6s;
      }
    }
  }
  .sec3 {
    .scrolling-box {
      h3.ani {
        span {
          transform: none;
          transition-duration: 1.5s;
          transition-timing-function: ease-in;
        }
      }
    }
  }
  .sec4 {
    .title-box {
      h2 {
        &.ani.not {
          a {
            transition: none;
          }

          .txt-wrap {
            transition: none;
          }

          .arrow-txt {
            .arrow span {
              transition: none;
            }
          }
        }

        &:nth-child(2) {
          span,
          a {
            transition-delay: 0.1s;
          }
        }
      }

      p {
        span {
          transition-delay: 0.2s;
        }
      }
    }

    .circle-lists {
      li {
        overflow: visible;

        &:nth-child(1) .text-wrap {
          transition-delay: 0.5s;
        }
        &:nth-child(2) .text-wrap {
          transition-delay: 0.8s;
        }
        &:nth-child(3) .text-wrap {
          transition-delay: 1.1s;
        }
      }
    }

    .earth-lists {
      overflow: visible;

      &.not {
        ul li {
          transition: none;

          &:nth-child(1) {
            &:after {
              transition: none;
            }
          }
        }
      }

      ul {
        transition: none;

        li {
          opacity: 0;
          transform: translateY(20%);
          display: inline-block;
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);

          &:nth-child(1) {
            transition-delay: 0.4s;

            &:after {
              opacity: 0;
              width: 0;
              transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1) 0.7s;
              @media screen and (max-width: 767px) {
                width: 1px;
                height: 0;
              }
            }
          }

          &:nth-child(2) {
            transition-delay: 1s;
          }
        }
      }
      &.ani.active {
        ul {
          li {
            opacity: 1;
            transform: translateY(0);

            &:nth-child(1) {
              &:after {
                opacity: 1;
                width: 23vw;

                @media screen and (max-width: 767px) {
                  width: 1px;
                  height: 33vw;
                }
              }
            }
          }
        }
      }
    }

    .thank-lists > ul > li {
      overflow: visible;

      .li-wrap {
        transition-delay: 0.4s;
      }

      &:nth-child(2) .li-wrap {
        transition-delay: 0.5s;
      }
    }
  }
  .sec5 {
    overflow: visible;

    .wrap {
      overflow: hidden;
    }

    > * {
      transition: none;
      opacity: 1;
    }

    &.not {
      transition: none;
    }

    .p-box {
      &.not {
        p {
          > span {
            transition: none;
            transition-delay: unset !important;

            > span {
              transition: none;
              transition-delay: unset !important;
            }
          }
        }
      }

      p {
        transition: none;
        overflow: hidden;

        > span {
          display: inline-block;
          opacity: 0;
          transform: translateY(110%);
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:nth-child(2) span {
          transition-delay: 0.1s;
        }
        &:nth-child(3) span {
          transition-delay: 0.2s;
        }
        &:nth-child(4) span {
          transition-delay: 0.3s;
        }
      }

      &.ani.active {
        p {
          span {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }
  .sec6 {
    .inner {
      overflow: visible;

      &.not {
        transition: none;
      }

      > * {
        transition: none;
        display: block;
        transform: none;
      }
    }

    .img-box {
      img {
        opacity: 0;
        transform: scale(1.11);
        transition: 1.05s ease;
      }

      &.not {
        img {
          transition: none;
        }
      }

      &.ani.active {
        img {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .title-box {
      h1 {
        &:nth-child(2) span {
          transition-delay: 0.1s;
        }
        &:nth-child(3) i {
          transition-delay: 0.2s;
        }
      }
    }

    .text-box {
      &.not {
        p {
          span {
            transition: none;
          }
        }
      }

      p {
        transition: none;
        overflow: hidden;

        > span {
          opacity: 0;
          transform: translateY(110%);
          display: inline-block;
          transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:nth-child(2) > span {
          transition-delay: 0.1s;
        }
        &:nth-child(3) > span {
          transition-delay: 0.2s;
        }
        &:nth-child(4) > span {
          transition-delay: 0.3s;
        }
        &:nth-child(5) > span {
          transition-delay: 0.4s;
        }
      }

      &.ani.active {
        p > span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  /* //animation */
  .scroll-wrap {
    position: relative;
    transition: 1s;
  }

  .color-000 {
    background: #000 !important;
  }

  .color-ececec {
    background: #ececec !important;

    .sec5 .rolling .text-box > span {
      color: #000;

      sup {
        color: #000;
      }
    }
  }

  .color-fff {
    background: #fff !important;

    .sec6 {
      span,
      i {
        &::selection {
          background: #000;
          color: #fff;
        }
        &::-moz-selection {
          background: #000;
          color: #fff;
        }
      }

      .title-box h1 {
        span {
          color: #000;
        }

        i {
          color: #000;
        }
      }

      .right .text-box p span {
        color: #000;
      }

      .white-img {
        opacity: 0;
      }

      .black-img {
        opacity: 1 !important;
      }
    }
  }

  .sec1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 4vw;
    height: calc(var(--vh, 1vh) * 100);
    background: #fff;

    &.hide {
      opacity: 0 !important;
    }

    .inner {
      transform: scale(0.94);
      transform-origin: left top;
    }

    h1 {
      span {
        font-family: "Pretendard";
        font-size: 8.432539682539684vw;
        font-size: 9.920634920634921vw;
        font-weight: 500;
        line-height: 1.1;
        letter-spacing: -0.3vw;

        .noto-font {
          transition: none;
          font-family: "Noto Serif KR";
          font-weight: normal;
          line-height: 1.1;
        }
      }

      img,
      video,
      .video {
        display: inline-block;
        vertical-align: middle;
      }

      &.step1 {
        img {
          margin: -1.9vw 0 0 1.5vw;
          width: 37.43386243386243vw;
        }
      }
      &.step2 {
        margin-top: -0.2vw;

        video,
        .video {
          margin: -1.5vw 1.2vw 0 0;
          width: 18.84920634920635vw;
        }

        img {
          margin: -1.7vw 0 0 0.7vw;
          width: 7.275132275132275vw;
        }
      }
      &.step3 {
        margin-top: 1.4vw;

        img {
          margin: -2vw 1.1vw 0 0.8vw;
          width: 15.01322751322751vw;
        }
      }
      &.step4 {
        margin-top: 1vw;

        .img1 {
          margin: -1.85vw 1.65vw 0 0;
          width: 31.48148148148148vw;
        }

        .img2 {
          margin: -1.65vw 0.9vw 0 1.3vw;
          width: 11.97089947089947vw;
        }
      }
    }
  }

  .sec2 {
    position: relative;
    overflow: hidden;
    padding: calc(var(--vh, 1vh) * 140) 0 0.18vw;

    .marquee {
      position: absolute;
      left: 0;
      bottom: 1vw;
      display: inline-block;
      padding-left: 100%;
      font-size: 8.5vw;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: -0.303vw;
      white-space: nowrap;
      overflow: hidden;
      animation: marquee 26.5s linear infinite;
      will-change: transform;
      z-index: 1;

      i {
        margin-left: 0.9vw;
        letter-spacing: 0;
        font-weight: 500;
      }
    }
  }

  .sec3 {
    position: relative;
    background: #000;
    transition: 1s;

    &.active {
      background: #ececec;
    }

    .video-box {
      position: relative;
      width: 100%;
      height: 41.00529100529101vw;
      text-align: center;
      overflow: hidden;

      .video {
        &:before {
          z-index: 2;
        }
        iframe {
          z-index: 1;
        }
      }

      p {
        position: relative;
        z-index: 1;

        span {
          font-size: 1.322751322751323vw;
          letter-spacing: -0.027vw;
          color: #fff;
        }
      }

      h2 {
        position: relative;
        z-index: 1;

        span {
          font-size: 8.597883597883598vw;
          font-weight: auto;
          line-height: 1.2;
          letter-spacing: -0.22vw;
          color: #fff;

          .pd-font {
            font-weight: 500;
          }
        }
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .text-box {
      padding-bottom: 13.58vw;
      mix-blend-mode: difference;

      .p-box {
        padding-top: 1.5vw;
        display: flex;

        p {
          width: 50%;

          span {
            font-family: "Pretendard";
            font-size: 1.984126984126984vw;
            font-weight: 500;
            line-height: 1.47;
            color: #fff;

            &::selection {
              background: #fff;
              color: #000;
            }
            &::-moz-selection {
              background: #fff;
              color: #000;
            }
          }

          &.step1 {
            padding-right: 8vw;
          }

          &.step2 {
            padding: 0 4vw 0 0.8vw;
          }
        }
      }

      .scrolling-box {
        position: relative;
        margin-top: 33.55vw;
        text-align: center;

        h3 {
          span {
            font-family: "Pretendard";
            font-size: 6.613756613756614vw;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.19vw;
            color: #fff;

            &::selection {
              background: #fff;
              color: #000;
            }
            &::-moz-selection {
              background: #fff;
              color: #000;
            }
          }
        }

        .img-lists {
          position: absolute;
          top: 25%;
          left: 0;
          width: 100%;
          height: 50%;

          li {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
              opacity: 1;
            }

            img {
              width: 64.02116402116402vw;
            }
          }
        }
      }
    }
  }

  .sec4 {
    position: relative;
    background: #000;
    transition: 1s;

    .lists {
      > li {
        padding-bottom: 14.6vw;

        .title-box {
          padding-top: 3.7vw;

          h2 {
            > span {
              font-size: 8.928571428571429vw;
              line-height: 1.1;
              letter-spacing: -0.27vw;
            }
            a {
              margin: 0 0 2.7vw 1.99vw;
              display: inline-block;
              vertical-align: middle;

              .txt-wrap {
                display: block;
                font-size: 1.322751322751323vw;
                font-weight: 500;
                line-height: 1.53;

                .arrow {
                  margin: -0.4vw 0 0 0.4vw;
                  width: 0.9001322751322751vw;
                  height: 0.9259259259259259vw;

                  > span {
                    background: url(../../../public/images/about_arrow.png) center no-repeat;
                  }
                }
              }
            }

            &:nth-child(2) {
              margin-top: -0.9vw;
            }
          }

          p {
            margin: 0.2vw 0 0 0.2vw;
            display: inline-block !important;
            vertical-align: top;

            &.mo {
              display: none !important;
            }

            span {
              font-family: "Pretendard";
              font-size: 1.19047619047619vw;
              font-weight: 500;
              text-transform: capitalize;
              letter-spacing: 0.055vw;
              line-height: 1.72;
            }

            &.step2 {
              margin-left: 4.7vw;

              span {
                letter-spacing: -0.003vw;
              }
            }
          }
        }

        .circle-lists {
          margin-top: 3.3vw;
          text-align: center;

          ol {
            display: inline-block;

            li {
              margin: 0 -1.1vw;
              display: inline-block;

              &:nth-child(1) .text-wrap {
                background: #6fffe5;
              }
              &:nth-child(2) .text-wrap {
                background: #ffa621;
              }
              &:nth-child(3) .text-wrap {
                background: #55b8ff;
              }

              .text-wrap {
                position: relative;
                display: inline-block;
                width: 26.45502645502646vw;
                height: 26.45502645502646vw;
                border-radius: 100%;
                text-align: center;

                .num {
                  display: inline-block;
                  margin-top: 4.7vw;
                  font-family: "Playfair Display";
                  font-size: 2.645502645502646vw;
                  font-weight: 600;
                }

                p {
                  margin-top: 1.9vw;
                  font-size: 1.984126984126984vw;
                  font-weight: 600;
                  line-height: 1.46;
                  letter-spacing: -0.06vw;
                  text-transform: capitalize;
                }

                .bottom-txt {
                  display: inline-block;
                  margin-top: 2.9vw;
                  font-family: "Playfair Display";
                  font-size: 1.322751322751323vw;
                  font-weight: 600;
                  letter-spacing: -0.03vw;
                }
              }
            }
          }
        }

        .earth-lists {
          margin: 3.6vw 0 0.9vw;
          text-align: center;

          ul {
            li {
              position: relative;
              width: 26.45502645502646vw;
              height: 26.45502645502646vw;
              border-radius: 100%;
              display: inline-block;
              vertical-align: middle;

              &:nth-child(1) {
                margin-right: 21vw;
                border: 1px solid #000;

                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 21.8vw;
                  margin-top: -0.5px;
                  height: 1px;
                  background: #000;
                }
              }

              &:nth-child(2) {
                width: 27.5vw;
                height: 27.5vw;
              }

              p {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > span {
                  font-size: 2.645502645502646vw;
                  line-height: 1.3;
                  letter-spacing: -0.11vw;
                  text-transform: uppercase;

                  &:nth-child(1) {
                    margin-top: 0.55vw;
                  }

                  &:nth-child(2) {
                    margin-top: 1.57vw;
                  }
                }
              }

              video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .thank-lists {
          margin-top: 6.2vw;
          text-align: center;

          > ul {
            > li {
              display: inline-block;
              vertical-align: top;
              font-size: 0;

              &:last-child {
                margin-left: -2.3vw;
              }

              .title {
                display: block;
                font-size: 1.984126984126984vw;
                letter-spacing: -0.04vw;
                font-weight: 500;
              }

              .li-wrap {
                > ul {
                  margin-top: 1.1vw;
                  display: flex;

                  > li {
                    position: relative;
                    background: #000;
                    border-radius: 15.74074074074074vw;

                    &.oval {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 31.21693121693122vw;
                      height: 15.74074074074074vw;
                    }

                    .text-box {
                      margin-top: 0.4vw;
                      padding: 0 1.4vw;
                      font-size: 1.984126984126984vw;
                      line-height: 1.25;
                      letter-spacing: -0.06vw;
                      color: #fff;

                      &::selection {
                        background: #fff;
                        color: #000;
                      }
                      &::-moz-selection {
                        background: #fff;
                        color: #000;
                      }

                      span {
                        color: #fff;

                        &::selection {
                          background: #fff;
                          color: #000;
                        }
                        &::-moz-selection {
                          background: #fff;
                          color: #000;
                        }
                      }
                    }
                  }
                }
              }

              .sec4-img1 {
                display: inline-block;
                width: 41.46825396825397vw;
              }
            }
          }
        }
      }
    }
  }

  .sec5 {
    position: relative;
    padding-bottom: 19.6vw;

    span,
    i,
    sup {
      &::selection {
        background: #fff;
        color: #000;
      }
      &::-moz-selection {
        background: #fff;
        color: #000;
      }
    }

    h2 {
      padding-left: 2.25vw;
      mix-blend-mode: difference;

      &.ani.active {
        transform: translateY(0);
      }

      span {
        font-size: 8.928571428571429vw;
        line-height: 1.273;
        letter-spacing: -0.265vw;
        color: #fff;
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);

        i {
          color: #fff;
        }
      }
    }

    .swiper-wrapper {
      width: 143.7652116402116vw;
    }

    .marquee {
      display: inline-block;

      &.not {
        .rolling .text-box > span {
          transition: none;
        }
      }
    }

    .rolling {
      margin-top: 5.1vw;
      display: flex;
      align-items: center;
      transition: none;

      .text-box {
        display: flex;
        flex-direction: column;
        margin-top: -1.2vw;

        > span {
          display: flex;
          font-size: 5.291005291005291vw;
          text-transform: uppercase;
          line-height: 1.27;
          letter-spacing: -0.16vw;
          color: #fff;
          transition: 1s;

          sup {
            text-transform: none;
            display: inline-block;
            margin: -0.2vw 0 0 1.15vw;
            font-family: "Playfair Display";
            font-size: 3.25vw;
            letter-spacing: -0.14vw;
            color: #fff;
            transition: 1s;
          }
        }
      }

      .img1 {
        margin: 0 -2.13vw 0 -3.7vw;
        width: 23.80952380952381vw;
      }
      .img2 {
        margin: -4vw 2vw 0 0.1vw;
        width: 47.55291005291005vw;
      }
      .img3 {
        margin: -3vw 2vw 0 2vw;
        width: 13vw;
      }
    }

    .p-box {
      margin: 2.8vw 0 0 1.53vw;
      mix-blend-mode: difference;

      p {
        display: block;

        > span {
          font-family: "Pretendard";
          font-size: 1.984126984126984vw;
          line-height: 1.63;
          font-weight: 600;
          color: #fff;

          .pd-font {
            font-weight: normal;
            color: #fff;
          }
        }
      }
    }
  }

  .sec6 {
    position: relative;
    padding-bottom: 37vw;

    .inner {
      padding-bottom: 11.8vw;
    }

    span,
    i {
      &::selection {
        background: #fff;
        color: #000;
      }
      &::-moz-selection {
        background: #fff;
        color: #000;
      }
    }

    .right {
      text-align: right;
      font-size: 0;

      &.step1 {
        .img-box {
          margin-top: -20vw;
        }

        img {
          width: 39.41798941798942vw;
        }
      }

      &.step2 {
        margin: -7.6vw -1.587301587301587vw 0 0;

        img {
          width: 57.40740740740741vw;
        }
      }

      .img-box {
        display: inline-block;
      }

      .wrap {
        display: inline-block;
        font-size: 0;
      }

      .text-box {
        padding-top: 1vw;
        text-align: left;

        p {
          display: block;

          span {
            font-family: "Pretendard";
            font-size: 1.256613756613757vw;
            font-weight: bold;
            line-height: 1.63;
            letter-spacing: -0.003vw;
            color: #fff;

            .light {
              position: relative;
              display: inline-block;
              font-weight: lighter;
              line-height: 1.05;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: #000;
              }
            }
          }
        }
      }
    }

    .title-box {
      margin-top: -11.3vw;
      mix-blend-mode: difference;

      h1 {
        span {
          position: relative;
          font-size: 12.3vw;
          line-height: 1.07;
          letter-spacing: -0.35vw;
          color: #fff;

          .img {
            position: absolute;
            top: 0.85vw;
            left: 25.85vw;
            display: inline-block;
            width: 10.78042328042328vw;
            line-height: 0;

            img {
              vertical-align: top;
              transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);

              &.black-img {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              }
            }
          }
        }

        i {
          margin-top: -2.1vw;
          font-family: "Playfair Display";
          font-size: 8.597883597883598vw;
          line-height: 1.2;
          letter-spacing: 0.12vw;
          color: #fff;
        }
      }
    }
  }
}

// @media screen and (min-width: 1900px) {
//   .about {
//     .sec1 {
//       padding-top: 95px;

//       h1 {
//         span {
//           font-size: 160.218px;
//           letter-spacing: -5.7px;
//         }

//         &.step1 {
//           img {
//             margin: -26.6px 0 0 24.7px;
//             width: 711.24px;
//           }
//         }
//         &.step2 {
//           margin-top: -3.8px;

//           video {
//             margin: -24.7px 15.2px 0 0;
//             width: 304.41px;
//           }
//           img {
//             margin: -32.3px 0 0 13.3px;
//             width: 138.219px;
//           }
//         }
//         &.step3 {
//           margin-top: 29.45px;

//           img {
//             margin: -35.15px 20.9px 0 19px;
//             width: 138.84px;
//           }
//         }
//         &.step4 {
//           margin-top: 19px;

//           img {
//             margin: -33.25px 0 0 27.55px;
//             width: 193.33px;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 767px) {
  .about {
    .sec1 {
      padding-top: 13.1vw;

      .inner {
        transform: scale(1);
      }

      h1 {
        span {
          font-size: 13.33333333333333vw;
          line-height: 1.32;
          letter-spacing: -0.4vw;

          .noto-font {
            line-height: 1.1;
          }
        }

        &.step2 {
          margin: 0.3vw 0 -0.9vw -0.6vw;

          img {
            width: 64vw;
          }
        }

        &.step3 {
          video,
          .video {
            margin: -1vw 1.9vw 0 0;
            width: 29.333333333333334vw;
          }
        }
        &.step4 {
          margin-top: -0.3vw;

          img {
            margin: -2.8vw 1.8vw 0 2.6vw;
            width: 10.13333333333333vw;
          }
        }
        &.step5 {
          margin-top: 0.5vw;

          img {
            margin: -1.3vw 2.6vw 0 0;
            width: 21.06666666666667vw;
          }
        }
        &.step6 {
          img {
            margin: 1.1vw 0 -2vw;
            width: 53.6vw;
            vertical-align: top;
          }
        }
        &.step7 {
          margin-top: 0.5vw;

          img {
            margin: -2vw 2.1vw 0 2.5vw;
            width: 17.86666666666667vw;
          }
        }
      }
    }

    .sec2 {
      padding-top: calc(var(--vh, 1vh) * 110);

      .marquee {
        font-size: 12vw;
        line-height: 1.1;
        font-weight: 600;
        letter-spacing: -0.303vw;
        animation-duration: 21s;

        i {
          margin-left: 0.5vw;
          letter-spacing: 0;
          font-weight: 500;
        }
      }
    }

    .sec3 {
      .video-box {
        height: 38.13333333333333vw;
      }

      .text-box {
        padding-bottom: 70.33vw;

        .p-box {
          padding-top: 2.6vw;
          display: block;

          p {
            width: 100%;

            span {
              font-size: 5.066666666666667vw;
              line-height: 1.63;
            }

            &.step1 {
              padding-right: 0;
            }

            &.step2 {
              padding: 4.3vw 0 0;
            }
          }
        }

        .scrolling-box {
          margin-top: 61.6vw;

          h3 {
            span {
              font-size: 9.333333333333333vw;
              line-height: 1.43;
              letter-spacing: -0.28vw;
            }
          }
        }
      }
    }

    .sec4 {
      .lists {
        > li {
          padding-bottom: 58vw;

          &:last-child {
            margin-top: -12.5vw;
            padding-bottom: 26.9vw;
          }

          .title-box {
            padding-top: 1.2vw;

            h2 {
              > span {
                font-size: 13.33333333333333vw;
                line-height: 1.25;
                letter-spacing: -0.4vw;

                &::selection {
                  background: #000;
                  color: #fff;
                }
                &::-moz-selection {
                  background: #000;
                  color: #fff;
                }
              }
              a {
                margin: -0.8vw 0 0 0;

                .txt-wrap {
                  font-size: 4vw;
                  line-height: 1.53;

                  .arrow {
                    margin: 0.1vw 0 0 1.5vw;
                    width: 2.549333333333333vw;
                    height: 2.666666666666667vw;
                  }
                }
              }

              &:nth-child(2) {
                margin-top: 0;
              }
            }

            p {
              margin: 8.8vw 0 0 0;

              &.pc {
                display: none !important;
              }
              &.mo {
                display: block !important;
              }

              span {
                font-size: 4.8vw;
                letter-spacing: -0.02vw;
                line-height: 1.64;
              }

              &.step2 {
                margin: 1.3vw 0 0 0;

                span {
                  letter-spacing: 0;
                  line-height: 1.6;
                  font-size: 4.266666666666667vw;
                }
              }
            }
          }

          .circle-lists {
            margin-top: 19vw;

            ol {
              li {
                margin: -8.7vw 0;
                display: block;

                .text-wrap {
                  width: 60.26666666666667vw;
                  height: 60.26666666666667vw;

                  .num {
                    margin-top: 13.7vw;
                    font-size: 4vw;
                  }

                  p {
                    margin-top: 3.4vw;
                    font-size: 4vw;
                    line-height: 1.27;
                    letter-spacing: -0.16vw;
                  }

                  .bottom-txt {
                    margin-top: 2.6vw;
                    font-size: 3.733333333333333vw;
                    letter-spacing: -0.1vw;
                  }
                }
              }
            }
          }

          .earth-lists {
            margin: 12.6vw 0 0.9vw;

            ul {
              display: flex;
              flex-direction: column;
              align-items: center;

              li {
                width: 60.26666666666667vw;
                height: 60.26666666666667vw;

                &:nth-child(1) {
                  margin-right: 0;

                  &:after {
                    top: 84.5%;
                    left: 50%;
                    margin-top: 0;
                    margin-left: -0.5px;
                    z-index: 1;
                  }
                }

                &:nth-child(2) {
                  margin-top: 22.5vw;
                  width: 61.5vw;
                  height: 61.5vw;
                }

                p {
                  > span {
                    font-size: 5.333333333333333vw;
                    line-height: 1.3;
                    letter-spacing: -0.11vw;

                    &:nth-child(1) {
                      margin-top: -2vw;
                    }

                    &:nth-child(2) {
                      margin-top: 2.3vw;
                    }
                  }
                }
              }
            }
          }

          .thank-lists {
            margin-top: 16.9vw;

            > ul {
              > li {
                display: block;

                &:last-child {
                  margin-top: 7.2vw;
                }

                .title {
                  font-size: 5.333333333333333vw;
                  letter-spacing: -0.15vw;
                }

                .sec4-img1 {
                  margin-top: 1.9vw;
                  width: 77.33333333333333vw;
                }

                .li-wrap {
                  > ul {
                    justify-content: center;
                    margin-top: 1.3vw;

                    > li {
                      border-radius: 15.74074074074074vw;

                      &.oval {
                        width: 76vw;
                        height: 30.4vw;
                      }

                      &:not(.oval) {
                        margin-right: -6.2vw;
                        width: 29.38666666666667vw;
                        height: 29.38666666666667vw;

                        &:last-child {
                          margin-right: 0;
                        }
                      }

                      .text-box {
                        padding: 0 7.7vw;
                        font-size: 4vw;
                        letter-spacing: -0.12vw;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sec5 {
      padding-bottom: 44.6vw;

      h2 {
        padding: 18.9vw 0 0 3.7vw;

        span {
          font-size: 21.33333333333333vw;
          line-height: 0.965;
          letter-spacing: -0.63vw;
        }
      }

      .swiper-wrapper {
        width: 179.3173333333333vw;
      }

      .rolling {
        margin-top: 25.2vw;

        .text-box {
          margin-top: -1.2vw;

          > span {
            font-size: 6.666666666666667vw;
            line-height: 1.28;
            letter-spacing: -0.195vw;

            sup {
              margin: -0.2vw 0 0 1.3vw;
              font-size: 4.3vw;
            }
          }
        }

        .img1 {
          margin: 0.2vw -3vw 0 -3.7vw;
          width: 25.33333333333333vw;
        }
        .img2 {
          margin: -3.5vw 1vw 0 0.1vw;
          width: 63.2vw;
        }
        .img3 {
          margin: -4.5vw 2.2vw 0 2.2vw;
          width: 17vw;
        }
      }

      .p-box {
        margin: 5.6vw 0 0 3.6vw;

        p {
          > span {
            font-size: 4.533333333333333vw;
            line-height: 1.65;
          }
        }
      }
    }

    .sec6 {
      padding-bottom: 48vw;

      .inner {
        padding-bottom: 42.4vw;
      }

      .right {
        &.step1 {
          .img-box {
            margin-top: -22.8vw;
          }

          img {
            width: 44.53333333333333vw;
          }
        }

        &.step2 {
          margin: 3.4vw -1.587301587301587vw 0 0;

          img {
            width: 76.53333333333333vw;
          }
        }

        .wrap {
          display: block;
          padding-right: 1.5vw;
        }

        .text-box {
          padding-top: 3.2vw;

          p {
            span {
              font-size: 4.266666666666667vw;
              line-height: 1.68;
            }
          }
        }
      }

      .title-box {
        position: relative;
        margin-top: -10vw;
        padding-left: 0.3vw;
        z-index: 1;

        h1 {
          span {
            font-size: 12.4vw;
            line-height: 1.13;
            letter-spacing: -0.37vw;

            img {
              top: 1.5vw;
              left: 28vw;
              width: 16.53333333333333vw;
            }
          }

          i {
            margin-top: -2.7vw;
            font-size: 12vw;
            letter-spacing: 0.18vw;
          }
        }
      }
    }
  }
}
