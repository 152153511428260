.archive {
  /* animation */
  .content-lists {
    li {
      span {
        opacity: 0;
        transform: translateY(110%);
        transition: 1.05s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &.ani.active {
        &.not {
          span {
            transition: none;
          }
        }
        span {
          opacity: 1;
          transform: translateY(0);
        }
        &:nth-child(1) {
          span {
            transition-delay: 0.01s;
          }
          &:after {
            transition-delay: 0.01s;
          }
        }
        &:nth-child(2) {
          span {
            transition-delay: 0.02s;
          }
          &:after {
            transition-delay: 0.02s;
          }
        }
        &:nth-child(3) {
          span {
            transition-delay: 0.03s;
          }
          &:after {
            transition-delay: 0.03s;
          }
        }
        &:nth-child(4) {
          span {
            transition-delay: 0.04s;
          }
          &:after {
            transition-delay: 0.04s;
          }
        }
        &:nth-child(5) {
          span {
            transition-delay: 0.05s;
          }
          &:after {
            transition-delay: 0.05s;
          }
        }
        &:nth-child(6) {
          span {
            transition-delay: 0.06s;
          }
          &:after {
            transition-delay: 0.06s;
          }
        }
        &:nth-child(7) {
          span {
            transition-delay: 0.07s;
          }
          &:after {
            transition-delay: 0.07s;
          }
        }
        &:nth-child(8) {
          span {
            transition-delay: 0.08s;
          }
          &:after {
            transition-delay: 0.08s;
          }
        }
        &:nth-child(9) {
          span {
            transition-delay: 0.09s;
          }
          &:after {
            transition-delay: 0.09s;
          }
        }
        &:nth-child(10) {
          span {
            transition-delay: 0.1s;
          }
          &:after {
            transition-delay: 0.1s;
          }
        }
      }
    }
  }
  .news-lists {
    > ul > li {
      .img-box {
        img {
          opacity: 0;
          transform: scale(1.11);
          transition: 1.05s ease;
        }
      }

      .img-box {
        &.ani.active {
          img {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }

  .sec1 {
    h1 {
      margin: 4.9vw 0 15.2vw 0.12vw;
      span {
        font-size: 9.920634920634921vw;
        line-height: 1;
        letter-spacing: -0.29vw;
      }
    }
  }

  .sec-title {
    padding-bottom: 0.78vw;

    span {
      display: inline-block;
      font-size: 5.291005291005291vw;
      line-height: 1.33;
      letter-spacing: -0.14vw;
    }
  }

  .year-lists {
    > ul {
      > li {
        padding-top: 0.85vw;
        display: flex;

        &:first-child {
          padding-top: 0;
        }
      }
    }

    .year {
      width: 17%;

      .txt {
        overflow: hidden;
        font-size: 0;
        display: block;

        span {
          display: inline-block;
          font-size: 2.314814814814815vw;
          line-height: 2;
          letter-spacing: -0.06vw;
        }
      }
    }

    &.award {
      padding-bottom: 17.4vw;

      .title {
        display: block;
        font-size: 0;
        overflow: hidden;

        > span {
          display: inline-block;
          font-size: 1.984126984126984vw;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.06vw;
        }
      }
    }

    &.press {
      padding-bottom: 15.1vw;

      > ul > li {
        padding-top: 6.5vw;

        &:first-child {
          padding-top: 0;
        }

        & {
          .title-lists {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 1px;
              background: #000;
            }
          }
        }
      }

      .content-lists {
        padding: 0;

        > li {
          position: relative;
          padding: 0.75vw 0 0.3vw;
          display: block;
          overflow: hidden;
          font-size: 0;

          button {
            width: 100%;
            text-align: left;

            > span {
              font-size: 1.785714285714286vw;
              line-height: 1.8;
              letter-spacing: -0.055vw;

              .ko {
                font-family: "Pretendard";
                font-size: 1.653439153439153vw;
                font-weight: 600;
              }
            }
          }

          .img-box {
            display: none;
            font-size: 0;
            transition: none;

            img {
              opacity: 0;
              transition: opacity 0.3s;
            }

            &.on {
              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .title-lists {
      width: 83%;

      > li {
        .title {
          position: relative;
          padding: 0.65vw 0 0.45vw;

          span {
            img {
              margin: -0.3vw 0.7vw 0 0;
              width: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .content-lists {
      padding: 0.8vw 0 4.5vw;

      > li {
        > span {
          display: inline-block;
          font-size: 1.653439153439153vw;
          letter-spacing: -0.05vw;
        }
      }
    }
  }

  .sec4 {
    .sec-title {
      border-bottom: none;
    }
  }

  .news-lists {
    overflow: hidden;
    padding: 3.15vw 0.1322751322751323vw 0;

    ul {
      display: block;
      margin: -1.6vw -0.8597883597883598vw;

      li {
        display: inline-block;
        width: 33.33333%;
        float: left;
        padding: 1.6vw 0.8597883597883598vw;

        a {
          width: 100%;

          .img-box {
            overflow: hidden;
            position: relative;
            padding-top: 54.15778251599147%;

            img {
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text-box {
            overflow: hidden;
            font-size: 0;
            display: block;
            padding-top: 0.78vw;

            span {
              display: inline-block;
              width: 100%;
              font-family: "Pretendard";
              font-size: 1.19047619047619vw;
              font-weight: 600;
              letter-spacing: -0.035vw;
              line-height: 1.52;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .archive {
    .sec1 {
      h1 {
        margin: 14.7vw 0 26.8vw 0.5vw;
        span {
          font-size: 10.66666666666667vw;
          letter-spacing: -0.31vw;
        }
      }
    }

    .sec-title {
      padding-bottom: 0.7vw;

      span {
        font-size: 8vw;
        line-height: 1.35;
        letter-spacing: -0.23vw;
      }
    }

    .year-lists {
      > ul {
        > li {
          padding-top: 6.85vw;
          display: block;
        }
      }

      .year {
        width: 100%;

        .txt {
          span {
            font-size: 4.8vw;
            letter-spacing: -0.15vw;
          }
        }
      }

      &.award {
        padding-bottom: 51.6vw;

        .title {
          > span {
            font-size: 4.8vw;
            letter-spacing: -0.15vw;
          }
        }
      }

      &.press {
        padding-bottom: 58.5vw;

        > ul > li {
          padding-top: 17.3vw;
        }

        .content-lists {
          > li {
            padding: 0;

            button {
              padding: 1.2vw 0 0.86vw;

              > span {
                font-size: 4vw;
                letter-spacing: -0.12vw;

                .ko {
                  font-size: 4vw;
                }
              }
            }

            .img-box {
              padding: 6.9vw 0 2.3vw;
            }
          }
        }
      }

      .title-lists {
        width: 100%;

        > li {
          .title {
            padding: 1.6vw 0 0;

            span {
              img {
                margin: -1.5vw 1.2vw 0 0;
                height: 3.733333333333333vw;
              }
            }
          }
        }
      }

      .content-lists {
        padding: 1.9vw 0 9.2vw;

        > li {
          > span {
            font-size: 4.533333333333333vw;
            letter-spacing: -0.136vw;
            line-height: 1.59;
          }
        }
      }
    }

    .news-lists {
      padding: 1.7vw 0 0;

      ul {
        margin: -6.3vw -1.866666666666667vw;

        li {
          width: 50%;
          padding: 6.3vw 1.866666666666667vw;

          a {
            .img-box {
              padding-top: 63.47305389221557%;
            }

            .text-box {
              padding-top: 2.1vw;

              span {
                font-size: 3.466666666666667vw;
                letter-spacing: -0.1vw;
                line-height: 1.6;
              }
            }
          }
        }
      }
    }
  }
}
