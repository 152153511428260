.black {
  .category {
    button.line-top:before {
      display: none;
    }
    .category-lists {
      .line-bottom:after {
        bottom: -0.5px;
      }
    }
  }
}
.fade-exit-active {
  .category .category-lists li > a:hover {
    background: none !important;
  }
}

.category {
  position: relative;

  .filter-lists .invert-btn.on {
    &:before {
      height: calc(100% + 2px);
    }
  }

  .arrow-txt {
    display: none;
  }

  button .btn-wrap {
    width: 100%;
    transition: none;

    > span,
    a {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
    }
  }

  .category-lists li > a,
  > button {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    line-height: 1;

    .num {
      > span {
        font-family: "Playfair Display";
        font-size: 3.306878306878307vw;
      }
    }

    h2 {
      letter-spacing: -0.1vw;
    }

    .description {
      position: absolute;
      top: 50%;
      right: 3.6%;
      width: 22.08068783068783vw;
      transform: translateY(-48%);
    }
    .description span {
      width: 100%;

      span {
        opacity: 0;
        display: inline-block;
        font-family: "Pretendard";
        font-size: 1.058201058201058vw;
        font-weight: 600;
        letter-spacing: -0.08vw;
        line-height: 1.5vw;
        color: #fff;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 3.694444444444444vw;
          padding-top: 3.694444444444444vw;
          background: url(../../../../public/images/work_category_arrow2_w.png) no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }

  > button {
    padding: 1.32vw 1.6vw 1.38vw;
    transition: all 0.2s;

    &:hover {
      background: #000;

      .num span {
        color: #fff;
      }

      h2 {
        .txt {
          color: #fff;

          &:before {
            opacity: 1;
            background: url(../../../../public/images/work_category_arrow1.png) center no-repeat;
            background-size: cover;
          }
          &:after {
            opacity: 0;
          }
        }

        i {
          color: #fff;
        }
      }
    }

    &.on {
      background: #000;

      .num span {
        color: #fff;
      }

      h2 {
        .txt {
          color: #fff;

          &:before {
            opacity: 0;
            transform: rotate(-180deg);
          }
          &:after {
            opacity: 1;
            transform: rotate(0);
          }
        }

        i {
          color: #fff;
        }
      }
    }

    .num {
      width: 16.9%;
    }

    h2 {
      position: relative;
      width: 83.1%;

      .txt {
        position: relative;
        display: inline-block;
        width: 100%;
        font-size: 4.62962962962963vw;
        line-height: 1.1;

        &:before {
          content: "";
          position: absolute;
          top: 16%;
          right: 1.3%;
          width: 3.647486772486772vw;
          height: 3.647486772486772vw;
          background: url(../../../../public/images/work_category_arrow.png) center no-repeat;
          background-size: cover;
          transition: 0.4s;
        }
        &:after {
          opacity: 0;
          content: "";
          position: absolute;
          top: 16%;
          right: 1.3%;
          width: 3.647486772486772vw;
          height: 3.647486772486772vw;
          background: url(../../../../public/images/work_category_arrow_w.png) center no-repeat;
          background-size: cover;
          transform: rotate(-180deg);
          transition: 0.4s;
        }

        .txt {
          position: relative;
          z-index: 1;
        }

        i {
          font-family: "Playfair Display";
        }
      }
    }
  }

  .category-lists {
    display: none;

    li {
      padding-left: 17.72486772486772%;

      > a {
        padding: 1.553vw 0 2.02vw;
        transition: all 0.1s;

        .num {
          width: 8.3%;
        }

        h2 {
          > div > span {
            font-size: 3.968253968253968vw;
          }

          i {
            font-family: "Playfair Display";
          }
        }

        &:hover {
          padding-left: 2vw;
          background: #000;

          .num span {
            color: #fff;
          }

          h2 {
            .txt {
              color: #fff;
            }

            i {
              color: #fff;
            }
          }

          .description span span {
            opacity: 1;
          }
        }
      }
    }
  }

  .filter {
    padding: 0.6vw 0;
    margin-left: 18%;
    font-size: 0;
    display: flex;
    align-items: center;

    h4 {
      margin: 0.6vw 0 0.4vw;
      width: 9.45%;
      font-size: 1.984126984126984vw;
      line-height: 1.3;
      letter-spacing: -0.06vw;
    }

    .filter-lists {
      width: 90%;

      li {
        padding: 0.3vw 0.5vw;
        display: inline-block;
      }
    }
  }
}

.black {
  .category {
    .category-lists li > a,
    > button {
      h2 {
        .txt {
          color: #fff;
        }
      }
    }

    > button {
      .num {
        span {
          color: #fff;
        }
      }

      &:hover {
        background: #fff;

        .num span {
          color: #000;
        }

        h2 {
          .txt {
            color: #000;

            &:before {
              opacity: 1;
              background: url(../../../../public/images/work_category_arrow.png) center no-repeat;
              background-size: cover;
            }
            &:after {
              opacity: 0;
            }
          }

          i {
            color: #000;
          }
        }
      }

      &.on {
        background: #fff;

        .num span {
          color: #000;
        }

        h2 {
          .txt {
            color: #000;

            &:before {
              opacity: 0;
              transform: rotate(-180deg);
            }
            &:after {
              opacity: 1;
              transform: rotate(-180deg);
            }
          }

          i {
            color: #000;
          }
        }
      }

      h2 {
        .txt {
          &:before {
            background: url(../../../../public/images/work_category_arrow1.png) center no-repeat;
            background-size: cover;
            transform: rotate(0);
          }
          &:after {
            background: url(../../../../public/images/work_category_arrow.png) center no-repeat;
            background-size: cover;
            transform: rotate(-360deg);
          }
        }

        i {
          color: #fff;
        }
      }
    }

    .category-lists {
      li {
        > a {
          overflow: visible;

          .num {
            span {
              color: #fff;
            }
          }

          h2 {
            i {
              color: #fff;
            }
          }

          &:hover {
            background: #fff;

            .num span {
              color: #000;
            }

            h2 {
              .txt {
                color: #000;
              }

              i {
                color: #000;
              }
            }

            .description span span {
              color: #000;

              &:before {
                background: url(../../../../public/images/work_category_arrow2.png) no-repeat;
                background-size: 100% auto;
              }
            }
          }
        }
      }
    }

    .arrow-txt {
      position: absolute;
      top: 8.9vw;
      left: 1.6vw;
      font-size: 1.984126984126984vw;
      color: #fff;
      display: block;

      .arrow {
        margin: -0.4vw 0.2vw 0 0.55vw;
        width: 1.195767195767196vw;
        height: 1.195767195767196vw;

        span {
          background: url(../../../../public/images/work_all_project_arrow.png) center no-repeat;
        }
      }

      span {
        color: #fff;
      }
      i {
        color: #fff;
      }
    }

    .filter {
      h4 {
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .category {
    .category-lists li > a,
    > button {
      .num {
        > span {
          font-size: 6.666666666666667vw;
        }
      }

      h2 {
        > span {
          letter-spacing: -0.2vw;
        }
      }
    }

    > button {
      padding: 3.32vw 2.8vw 3.38vw 3.6vw;

      &:hover {
        background: #fff;

        .num span {
          color: #000;
        }

        h2 {
          .txt {
            color: #000;

            &:before {
              opacity: 1;
              background: url(../../../../public/images/work_category_arrow_m.png) center no-repeat;
              background-size: cover;
            }
            &:after {
              opacity: 0;
            }
          }

          i {
            color: #000;
          }
        }
      }

      &.on {
        background: #000;

        .num span {
          color: #fff;
        }

        h2 {
          .txt {
            color: #fff;

            &:before {
              opacity: 0;
            }
            &:after {
              opacity: 1;
              transform: rotate(-180deg);
            }
          }

          i {
            color: #fff;
          }
        }
      }

      .num {
        width: 17.2%;
      }

      h2 {
        width: 82.8%;

        .txt {
          font-size: 8vw;

          &:before {
            top: 14%;
            width: 6.266666666666667vw;
            height: 6.266666666666667vw;
            background: url(../../../../public/images/work_category_arrow_m.png) center no-repeat;
            background-size: cover;
          }
          &:after {
            top: 14%;
            width: 6.266666666666667vw;
            height: 6.266666666666667vw;
            background: url(../../../../public/images/work_category_arrow_w_m.png) center no-repeat;
            background-size: cover;
            transform: rotate(-360deg);
          }
        }
      }
    }

    .category-lists {
      li {
        padding-left: 19.6%;

        > a {
          padding: 3vw 0 3.6vw;

          .num {
            width: 20.7%;
          }

          h2 {
            > div > span {
              font-size: 8vw;
            }
          }

          .description {
            display: none;
          }

          &:hover {
            padding-left: 0;
            background: none;

            .num span {
              color: #000;
            }

            h2 {
              .txt {
                color: #000;
              }

              i {
                color: #000;
              }
            }
          }
        }
      }
    }

    .filter {
      padding: 2.5vw 0 2.3vw;
      margin-left: 19.6%;

      h4 {
        margin: 0.3vw 0 0;
        width: 20.3%;
        font-size: 4.266666666666667vw;
        line-height: 1.3;
        letter-spacing: -0.06vw;
      }

      .filter-lists {
        width: 90%;

        li {
          padding: 0.8vw 1.35vw;

          .btn-wrap {
            /*23.04.24 필터수정*/
            /*width: 14.13333333333333vw;*/
            border-radius: 3.6vw;

            span,
            a {
              display: block;
              /*padding: 1.27vw 0 1.07vw;*/
              padding: 1.27vw 3vw 1.07vw;
              text-align: center;
              line-height: 4vw;
            }
          }
        }
      }
    }
  }

  .black {
    .category {
      > button {
        &.on {
          h2 {
            .txt {
              &:before {
                transform: rotate(180deg);
              }
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }

        h2 {
          .txt {
            &:before {
              transform: rotate(0);
              background: url(../../../../public/images/work_category_arrow_w_m.png) center no-repeat;
              background-size: cover;
            }
            &:after {
              display: block;
              transform: rotate(0);
              background: url(../../../../public/images/work_category_arrow_m.png) center no-repeat;
              background-size: cover;
            }
          }
        }
      }
      .category-lists {
        li {
          > a {
            &:hover {
              background: transparent;

              .num span {
                color: #fff;
              }

              h2 {
                .txt {
                  color: #fff;
                }

                i {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .arrow-txt {
        position: relative;
        top: auto;
        left: auto;
        margin-left: 19.6%;
        padding-top: 1.3vw;
        font-size: 4.8vw;
        letter-spacing: -0.12vw;
        display: inline-block;

        .arrow {
          margin: -1vw 1vw 0 1.3vw;
          width: 2.944vw;
          height: 2.944vw;
        }
      }
    }
  }
}
